import React, { useEffect, useState } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { enableDisableTwoStepAuthStart } from "../../store/actions/UserAction";
import OtpInput from "react-otp-input";
import { ButtonLoader } from "../helper/Loader";

const EnableDisableTwoStepAuth = (props) => {
  const dispatch = useDispatch();
  const enableDisableTwoStepAuth = useSelector(
    (state) => state.user.enableDisableTwoStepAuth
  );
  const t = useTranslation("enable_disable_twostep_auth");
  const [skipRender, setSkipRender] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = (values) => {
    dispatch(enableDisableTwoStepAuthStart(values));
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("required"))
      .min(6, t("password.min_length_invalid"))
      .max(16, t("password.max_length_invalid")),
    verification_code: Yup.string()
      .required(t("required"))
      .test("otp length", "Six digits required", (value) => {
        if (!value) return false;
        return value && value.length == 6;
      }),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !enableDisableTwoStepAuth.loading &&
      Object.keys(enableDisableTwoStepAuth.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [enableDisableTwoStepAuth]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-otp-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
        <div className="two-auth-icons">
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 101 101"
    >
      <path
        fill="#f9c201"
        d="M93.84 19.14A68.47 68.47 0 0154.77 4.06a6.89 6.89 0 00-8.64 0A68.44 68.44 0 017.06 19.13a6.8 6.8 0 00-6.45 6.52c-.67 18.55 3.21 59.47 47.65 74a7 7 0 004.38 0c44.51-14.56 48.36-55.45 47.66-74a6.8 6.8 0 00-6.46-6.51zm-43.38 4.74a12.47 12.47 0 11-12.59 12.46 12.53 12.53 0 0112.59-12.46zm20.68 46.53H29.76v-4.93a7 7 0 013.43-6l10.45-4.31 6 4.33a1.34 1.34 0 00.79.26h.12a1.29 1.29 0 00.79-.26l6-4.33 10.45 4.31a7 7 0 013.43 6z"
        data-original="#000000"
      ></path>
    </svg>
        </div>
          <div className="auth_modal_titles out_space_mx_1_5">
            <h2>{t("heading")}</h2>
            <p>{t("content")}</p>
          </div>
          <Formik
            initialValues={{
              verification_code: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM>
                <div className="auth_modal_content">
                  <div className="auth_modal_otp out_space_mx_1_5">
                    <OtpInput
                      className="auth_otp_box out_space_my_1"
                      value={values.verification_code}
                      onChange={(e) => {
                        setFieldValue("verification_code", e);
                      }}
                      inputType="tel"
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                      shouldAutoFocus
                    />
                    <ErrorMessage
                      component={"div"}
                      name="verification_code"
                      className="errorMsg"
                    />
                  </div>
                  <div className="change_password_content">
                    <div className="change_password_form  out_space_mx_1_5">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("password.label")}
                          {""} <span>*</span>
                        </Form.Label>
                        <InputGroup>
                          <Field
                            name="password"
                            className="form-control"
                            type={`${passwordVisible ? "text" : "password"}`}
                            placeholder={t("password.placeholder")}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#DDE1E6"
                                  d="M12 16.33c-2.39 0-4.33-1.94-4.33-4.33S9.61 7.67 12 7.67s4.33 1.94 4.33 4.33-1.94 4.33-4.33 4.33zm0-7.16c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83 2.83-1.27 2.83-2.83S13.56 9.17 12 9.17z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M12 21.02c-3.76 0-7.31-2.2-9.75-6.02-1.06-1.65-1.06-4.34 0-6 2.45-3.82 6-6.02 9.75-6.02s7.3 2.2 9.74 6.02c1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54c-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38 2.16 3.39 5.25 5.33 8.48 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-2.16-3.39-5.25-5.33-8.48-5.33z"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#DDE1E6"
                                  d="M9.47 15.28c-.19 0-.38-.07-.53-.22A4.305 4.305 0 017.67 12c0-2.39 1.94-4.33 4.33-4.33 1.15 0 2.24.45 3.06 1.27a.75.75 0 010 1.06L10 15.06c-.15.15-.34.22-.53.22zM12 9.17a2.834 2.834 0 00-2.46 4.23l3.86-3.86c-.42-.24-.9-.37-1.4-.37z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M5.6 18.51c-.17 0-.35-.06-.49-.18-1.07-.91-2.03-2.03-2.85-3.33-1.06-1.65-1.06-4.34 0-6C4.7 5.18 8.25 2.98 12 2.98c2.2 0 4.37.76 6.27 2.19a.75.75 0 01-.9 1.2c-1.64-1.24-3.5-1.89-5.37-1.89-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38s1.61 2.18 2.56 3c.31.27.35.74.08 1.06-.14.17-.35.26-.56.26zM12 21.02c-1.33 0-2.63-.27-3.88-.8a.75.75 0 01-.4-.98c.16-.38.6-.56.98-.4 1.06.45 2.17.68 3.29.68 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-.31-.49-.65-.96-1.01-1.4a.76.76 0 01.11-1.06.75.75 0 011.06.11c.39.48.77 1 1.11 1.54 1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M12.69 16.27c-.35 0-.67-.25-.74-.61-.08-.41.19-.8.6-.87 1.1-.2 2.02-1.12 2.22-2.22.08-.41.47-.67.88-.6.41.08.68.47.6.88-.32 1.73-1.7 3.1-3.42 3.42-.05-.01-.09 0-.14 0zM2 22.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L8.94 14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.47 7.47c-.15.15-.34.22-.53.22zM14.53 10.22c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.47-7.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L15.06 10c-.15.15-.34.22-.53.22z"
                                ></path>
                              </svg>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </div>
                    <div className="change_password_action item_flex_x_5">
                      <Button
                        className="action-btn overlay w-100"
                        onClick={() => {
                          props.onHide();
                        }}
                        disabled={enableDisableTwoStepAuth.buttonDisable}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="action-btn primary w-100"
                        type="submit"
                        disabled={enableDisableTwoStepAuth.buttonDisable}
                      >
                        {enableDisableTwoStepAuth.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("submit_btn.text")
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </FORM>
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default EnableDisableTwoStepAuth;
