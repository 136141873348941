import React, { useState, useEffect } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import {
  emailVerifyStart,
  forgotPasswordStart,
  verifyForgotPasswordStart,
} from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { ButtonLoader } from "../../components/helper/Loader";
import {
  resendOtpStart,
  twoStepVerificationStart,
} from "../../store/actions/UserAction";
import { useTranslation } from "react-multi-lang";

const OtpVerification = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("otp_verification");
  const emailVerify = useSelector((state) => state.user.emailVerify);
  const verifyForgotPassword = useSelector(
    (state) => state.user.verifyForgotPassword
  );
  const forgotPassword = useSelector((state) => state.user.forgotPassword);
  const twoStepVerification = useSelector(
    (state) => state.user.twoStepVerification
  );
  const resendOtp = useSelector((state) => state.user.resendOtp);
  const [otp, setOtp] = useState("");
  const [otpButton, setOtpButton] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [expirationTime, setExpirationTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        if (props.showOtpVerify.type == "2fa") {
          dispatch(
            twoStepVerificationStart({
              verification_code: otp,
              email: props.showOtpVerify.email,
              password: props.showOtpVerify.password,
            })
          );
        } else {
          dispatch(
            emailVerifyStart({
              email: props.showOtpVerify.email,
              verification_code: otp,
              password: props.password,
            })
          );
        }
      } else {
        getErrorNotificationMessage(t("verification_code_length_error"));
      }
    } else {
      getErrorNotificationMessage(t("verification_code_required_error"));
    }
  };

  const handleresendOtp = () => {
    setOtp("");
    if (props.forgotPassword && props.forgotPassword === 2) {
      dispatch(
        forgotPasswordStart({
          email: props.showOtpVerify.email,
        })
      );
    } else {
      dispatch(
        resendOtpStart({
          email: props.showOtpVerify.email,
          code_type: "email",
        })
      );
    }
  };

  useEffect(() => {
    // if (
    //   !skipRender &&
    //   !emailVerify.loading &&
    //   Object.keys(emailVerify.data).length > 0
    // ) {
    //   if (emailVerify.data.email_status == 1) {
    //     props.closeOtpVerifyModal();
    //     navigate("/");
    //   }
    // }
    if (
      !skipRender &&
      !twoStepVerification.loading &&
      Object.keys(twoStepVerification.data).length > 0
    ) {
      if (twoStepVerification.data.user.email_status == 1) {
        props.closeOtpVerifyModal();
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [twoStepVerification]);

  useEffect(() => {
    const now = new Date().getTime();
    const expireTime = now + 17000;
    setExpirationTime(expireTime);
  }, [resendOtp]);

  function calculateTimeLeft() {
    if (!expirationTime) return 0;
    const currentTime = new Date().getTime();
    const timeLeft = expirationTime - currentTime;
    return timeLeft > 0 ? timeLeft : 0;
  }

  useEffect(() => {
    if (!expirationTime) return;
    const timer =
      props.showOtpVerify.type !== "2fa" &&
      setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft <= 0) {
          clearInterval(timer);
          setIsExpired(true);
          setTimeLeft(0);
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);

    return () => clearInterval(timer);
  }, [expirationTime]);

  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (
      !skipRender &&
      !resendOtp.loading &&
      Object.keys(resendOtp.data).length > 0
    ) {
      setIsExpired(false);
      const now = new Date().getTime();
      const expireTime = now + 16000;
      setExpirationTime(expireTime);
    }
    setSkipRender(false);
  }, [resendOtp]);

  return (
    <>
      <Modal
        show={props.showOtpVerify}
        onHide={props.closeOtpVerifyModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="auth-otp-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="auth_modal_content">
            <div className="auth_modal_avater out_space_mx_1_5">
              <Image
                className="auth_modal_img"
                src={
                  window.location.origin + "/img/phase_1/auth/otp_verifiyy.png"
                }
                alt="Auth Otp"
              />
            </div>
            <div className="auth_modal_titles out_space_mx_1_5">
              <h2>
                {props.showOtpVerify.type == "email"
                  ? "OTP verification"
                  : "Two-Step verification"}
              </h2>
              <p>
                {t("input_the_code")}{" "}
                {props.showOtpVerify.type == "email"
                  ? t("email_otp")
                  : t("virtual_otp")}{" "}
                {t("for_verification")}.
              </p>
            </div>
            <div className="auth_modal_otp out_space_mx_1_5">
              <Formik
                initialValues={{
                  otp: "",
                }}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <FORM>
                    <OtpInput
                      className="auth_otp_box out_space_my_1"
                      value={otp}
                      onChange={setOtp}
                      inputType="tel"
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    <div className="auth_modal_actions">
                      <Button
                        className="action-btn primary w-100"
                        disabled={
                          emailVerify.buttonDisable ||
                          resendOtp.buttonDisable ||
                          verifyForgotPassword.buttonDisable ||
                          twoStepVerification.buttonDisable ||
                          forgotPassword.buttonDisable ||
                          otp.length != 6
                        }
                        type="submit"
                      >
                        {" "}
                        {emailVerify.buttonDisable ||
                          verifyForgotPassword.buttonDisable ||
                          twoStepVerification.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("validate")
                        )}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
            <div className="auth_modal_time_out out_space_mx_1_5">
              {props.showOtpVerify.type !== "2fa" && !isExpired && (
                <p>
                  {t("click_below_to_resend_otp")}
                  {formatTime(timeLeft)}
                </p>
              )}
            </div>
            <div className="auth_modal_resend_otp item_center_x_5">
              {props.showOtpVerify.type == "email" ? (
                <>
                  <p>{t("if_not_getting_otp")}?</p>
                  <Button
                    type="button"
                    className="resend_otp_btn"
                    onClick={handleresendOtp}
                    disabled={
                      emailVerify.buttonDisable ||
                      resendOtp.buttonDisable ||
                      verifyForgotPassword.buttonDisable ||
                      forgotPassword.buttonDisable ||
                      !isExpired
                    }
                  >
                    {t("resend_otp")}
                  </Button>{" "}
                </>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerification;
