import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Link, useParams } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useTranslation } from "react-multi-lang";
import {
  getCurrenciesListStart,
  withdrawalRequestStart,
} from "../../store/actions/BuyAction";
import configuration, { set } from "react-global-configuration";
import { recipientListStart, virtualAccountShowStart, virtualAccountWithdrawStart } from "../../store/actions/RecipientAction";
import { generateQuoteStart } from "../../store/actions/BuyAction";
import Select from "react-select";
import TransactionSuccess from "../helper/TransactionSuccess";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
} from "../../store/actions/WalletAction";
import ButtonStepper from "../Buy/ButtonStepper";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { Radio } from "pretty-checkbox-react";

const WalletWithdrawModal = (props) => {
  const formref = useRef();
  const params = useParams();
  const t = useTranslation("withdraw_modal");
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("");
  const [id, setId] = useState("");
  const [bank, setBank] = useState(null);
  const dispatch = useDispatch();
  const bankAccountList = useSelector((state) => state.bankAccount.bankAccount);
  const withdrawalRequest = useSelector((state) => state.buy.withdrawalRequest);
  const currencies = useSelector((state) => state.buy.currencies);
  const generateQuote = useSelector((state) => state.buy.generateQuote);
  const [skipRender, setSkipRender] = useState(true);
  const recipientList = useSelector((state) => state.recipient.recipientList);
  const virtualAccountShow = useSelector((state) => state.recipient.virtualAccountShow);
  const virtualAccountWithdraw = useSelector((state) => state.recipient.virtualAccountWithdraw);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);
  const [buttonStepper, setButtonStepper] = useState(false);
  const profile = useSelector((state) => state.user.profile);
  const [minAmount, setMinAmount] = useState(0.01);
  const [maxAmount, setMaxAmount] = useState(1);
  const [quoteValues, setQuoteValues] = useState(null);
  const [validDecimalValue, setValidDecimalValue] = useState();
  const [bankType, setBankType] = useState(Object.keys(props.wallet?.virtual_account).length > 0 ? "eficyent_account" : "herald_account");

  const handleSubmit = (values) => {
    if (bankType == "herald_account") {
      setAmount(values.amount);
      const { virtual_account_id, ...data } = values
      dispatch(
        generateQuoteStart({
          ...data,
          type: "forex",
          user_wallet_id: props.user_wallet_id,
          // stylopay_recipient_id: values.beneficiary_account_id
        })
      );
      setQuoteValues({
        ...values,
        type: "forex",
        user_wallet_id: props.user_wallet_id,
        stylopay_recipient_id: values.beneficiary_account_id
      })
    }
    else {
      dispatch(virtualAccountWithdrawStart({
        amount: values.amount,
        virtual_account_id: values.virtual_account_id,
        user_wallet_id: props.user_wallet_id,
      }))
    }
  };

  const handleSubmitWithDrawal = (values) => {

    values && dispatch(
      withdrawalRequestStart({
        ...values,
        type: "forex",
        user_wallet_id: props.user_wallet_id,
        stylopay_recipient_id: values.beneficiary_account_id
      })
    );
  };

  const validationSchema = Yup.object().shape({
    beneficiary_account_id: Yup.string().when(
      "virtual_account_id",
      (virtual_account_id) => {
        if (virtual_account_id == null) {
          return (
            Yup.string().required(t("bank_account.required"))
          );
        }
        return Yup.string()
      }
    ),
    amount: Yup.number()
      .required(t("amount.required"))
      .min(
        !isNaN(minAmount) ? minAmount?.toFixed(2) : 0,
        t("amount.invalid", { value: minAmount.toFixed(2) })
      )
      .max(
        maxAmount > parseFloat(props.show)
          ? maxAmount
          : parseFloat(props.show).toFixed(2),
        t("amount.max_invalid", { value: maxAmount })
      ),
  });

  useEffect(() => {
    dispatch(recipientListStart({ receiving_currency: props.currency }));
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !withdrawalRequest.loading &&
      Object.keys(withdrawalRequest.data).length > 0
    ) {
      setButtonStepper(true);
      setTimeout(() => {
        setTransactionSuccess(true);
      }, 3000);
    }
    setSkipRender(false);
  }, [withdrawalRequest]);

  useEffect(() => {
    if (
      !skipRender &&
      !generateQuote.loading &&
      Object.keys(generateQuote.data).length > 0
    ) {
      setStep(2);
    }
    setSkipRender(false);
  }, [generateQuote]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878e96",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
    dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: props.user_wallet_id,
      })
    );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      setMinAmount(
        parseInt(currencies.data?.user_commissions?.min_withdraw_amount) <= 0
          ? 0.01
          : parseInt(
            currencies.data?.user_commissions?.min_withdraw_amount
          ).toFixed(2)
      );
      setMaxAmount(
        parseInt(currencies.data?.user_commissions?.max_withdraw_amount) <= 0
          ? 100000
          : parseInt(
            currencies.data?.user_commissions?.max_withdraw_amount
          ).toFixed(2)
      );
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (!Object.keys(currencies.data).length > 0) {
      dispatch(
        getCurrenciesListStart({
          user_id: profile.data?.user_id ? profile.data?.user_id : "",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !virtualAccountWithdraw.loading &&
      Object.keys(virtualAccountWithdraw.data).length > 0
    ) {
      setButtonStepper(true);
      setTimeout(() => {
        setTransactionSuccess(true);
      }, 3000);
    }
    setSkipRender(false);
  }, [virtualAccountWithdraw]);

  useEffect(() => {
    if (
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      let decimal = (currencies.data.balance_steps.find(item => item.currency_code == props.currencyCode)?.balance_step);
      if (decimal) {
        decimal = decimal.slice(decimal.indexOf('.') + 1, decimal.indexOf('1') + 1).length
        setValidDecimalValue(decimal)
      }
    }
    setSkipRender(false);
  }, [currencies, props.currencyCode]);

  const onAmountChange = (value, setFieldValue) => {
    let decimal = value.slice(value.indexOf('.') + 1).length;
    if (value.includes('.')) {
      if (decimal <= validDecimalValue) {
        if (Number(value) == value) {
          setFieldValue("amount", value)
        }
      } else {
        getErrorNotificationMessage(`You can input upto ${validDecimalValue} decimal places.`)
      }
    } else {
      if (Number(value) == value) {
        setFieldValue("amount", value)
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className="crypto_modal"
        className="view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          {!transactionSuccess ? <Modal.Title id="contained-modal-title-vcenter">
            {t("withdraw_tokens")}
          </Modal.Title> : null}
        </Modal.Header>
        <Modal.Body>
          {!transactionSuccess ? (
            <div className="crypto_modal_content">
              {step == 1 ? (
                recipientList.loading ? (
                  <CommonCenterLoader />
                ) : Object.keys(recipientList.data).length > 0 &&
                  recipientList.data.total_beneficiary_accounts > 0 ? (
                  <>
                    <Formik
                      initialValues={{
                        amount: amount,
                        beneficiary_account_id: id,
                        virtual_account_id: (props.wallet?.virtual_account?.id ?? null)
                      }}
                      innerRef={formref}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ touched, errors, setFieldValue, values }) => (
                        <>
                          <div className="crypto_modal_titles out_space_mx_1_5">
                            <h4>
                              {" "}
                              {t("heading")} {`(${props.currency})`}
                            </h4>
                          </div>
                          <div className="crypto_modal_form">
                            <FORM>
                              <div className="crypto_amount_input out_space_mx_1_5">
                                <div className="crypto_amount_field margin-btm-sm">
                                  <Field
                                    type="number"
                                    name="amount"
                                    placeholder={`Enter the Amount in ${props.amount.slice(
                                      -4
                                    )}`}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      const remainingOriginal = props.show || 0;
                                      const percentage = (parseFloat(newValue) / remainingOriginal) * 100;
                                      setRangeValue(isNaN(percentage) ? 0 : Math.min(percentage, 100).toFixed(2));
                                      onAmountChange(newValue, setFieldValue)
                                    }}
                                    className="form-control"
                                    disabled={withdrawalRequest.buttonDisable}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="amount"
                                    className="text-danger text-right"
                                  />
                                </div>
                                <Form.Group
                                  controlId="exampleForm.ControlInput1"
                                  className="trade-percent-range mt-2 mb-2"
                                >
                                  <div className="range-label">
                                    <span>{rangeValue}%</span>
                                    <span>100%</span>
                                  </div>
                                  <Form.Range
                                    disabled={!props.show || props.show <= 0 || withdrawalRequest.buttonDisable}
                                    min="0"
                                    max="100"
                                    value={rangeValue}
                                    onChange={(e) => {
                                      const data =
                                        (parseFloat(e.target.value) / 100) *
                                        props.show;
                                      setRangeValue(e.target.value);
                                      setFieldValue("amount", data.toFixed(2));
                                    }}
                                  />
                                  <div className="trade-range-value">
                                    {rangeValue} % / 100 %
                                  </div>
                                </Form.Group>
                                <div className="crypto_amount-bls">
                                  <h5>
                                    {t("balance")} = <span> {props.amount}</span>
                                  </h5>
                                </div>
                                {props.wallet?.virtual_account?.id &&
                                  <div className="efi-swap-check-wrap">
                                    <div className="efi-swap-check-frame">
                                      <Radio
                                        value="eficyent_account"
                                        checked={bankType == "eficyent_account"}
                                        onClick={(e) => {
                                          setBankType(e.target.value)
                                          setFieldValue("virtual_account_id", props.wallet?.virtual_account?.id)
                                        }}
                                      >

                                        {t("eficyent_bank_account")}
                                      </Radio>
                                      <Radio
                                        value="herald_account"
                                        checked={bankType == "herald_account"}
                                        onClick={(e) => {
                                          setBankType(e.target.value)
                                          setFieldValue("virtual_account_id", null)

                                        }}


                                      >
                                        {t("beneficiary_list")}
                                      </Radio>
                                    </div>

                                  </div>}
                              </div>
                              {bankType == "eficyent_account" &&
                                <div className="bank-details">
                                  <h6>
                                    {t("bank_name")}
                                  </h6>
                                  <p>{props.wallet.virtual_account.virtual_account.account_bank_name}</p>
                                  <h6>
                                    {t("account_holder_name")}
                                  </h6>
                                  <p>{props.wallet.virtual_account.virtual_account.account_holder_name}</p>
                                  <h6>
                                    {t("account_number")}
                                  </h6>
                                  <p>{props.wallet.virtual_account.virtual_account.account_number}</p>

                                </div>}
                              {bankType == "herald_account" &&
                                <div className="crypto_address_card out_space_mx_1_5">
                                  <div className="crypto_address_field">
                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        {t("bank_account.label")}
                                      </Form.Label>
                                      <Select
                                        styles={customStyles}
                                        value={bank}
                                        options={recipientList.data.beneficiary_accounts.map(
                                          (account, i) => ({
                                            label: `${account.first_name} -
                                            ${account.account_number}`,
                                            value: account.id,
                                          })
                                        )}
                                        onChange={(data) => {
                                          setFieldValue("beneficiary_account_id", data.value);
                                          setBank(data);
                                        }}
                                        placeholder={
                                          <div className="placeholder-flex">
                                            {t("select_bank_account")}
                                          </div>
                                        }
                                        isDisabled={withdrawalRequest.buttonDisable}
                                      />

                                      <ErrorMessage
                                        component={"div"}
                                        name="beneficiary_account_id"
                                        className="text-danger text-right"
                                      />
                                    </Form.Group>
                                  </div>
                                  {/* commission calculation */}
                                  <div className="commission-sec">
                                    <div className="commission-box">
                                      {(!isNaN(values.amount * (currencies.data?.user_commissions?.withdraw_commission / 100)) && ((values.amount - values.amount * (currencies.data?.user_commissions?.withdraw_commission / 100)).toFixed(2) > 0)) ? <div className="commission-card">
                                        <h5>
                                          {t("service_fees", {
                                            value: props.currencyCode,
                                          })}
                                          :
                                        </h5>
                                        <h5>
                                          {!isNaN(
                                            values.amount *
                                            (currencies.data?.user_commissions?.withdraw_commission /
                                              100)
                                          )
                                            ? (
                                              values.amount *
                                              (currencies.data?.user_commissions?.withdraw_commission /
                                                100)
                                            ).toFixed(2)
                                            : "0.00"}
                                        </h5>
                                      </div> : null}
                                      <div className="commission-card">
                                        <h5>
                                          {t("user_receive_tokens", {
                                            value: props?.currencyCode,
                                          })}
                                          :
                                        </h5>
                                        <h5>
                                          {!isNaN(
                                            values.amount -
                                            values.amount *
                                            (currencies.data?.user_commissions
                                              ?.withdraw_commission /
                                              100)
                                          )
                                            ? (
                                              values.amount -
                                              values.amount *
                                              (currencies.data
                                                ?.user_commissions
                                                ?.withdraw_commission /
                                                100)
                                            ).toFixed(2)
                                            : "0.00"}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                              {virtualAccountWithdraw.buttonDisable ||
                                (Object.keys(virtualAccountWithdraw.data).length > 0 &&
                                  buttonStepper) ? (
                                <div className="withdraw-stepper">
                                  {" "}
                                  <ButtonStepper props={virtualAccountWithdraw} />{" "}
                                </div>
                              ) : (
                                <div className="crypto_action item_flex_x_5">
                                  <Button
                                    className="action-btn overlay w-100"
                                    onClick={props.onHide}
                                    disabled={generateQuote.buttonDisable}
                                  >
                                    {t("cancel")}
                                  </Button>
                                  <Button
                                    type="submit"
                                    className="action-btn primary w-100"
                                    disabled={generateQuote.buttonDisable || virtualAccountWithdraw.buttonDisable}
                                  >
                                    {(generateQuote.buttonDisable || virtualAccountWithdraw.buttonDisable)
                                      ? t("loading")
                                      : bankType == "eficyent_account" ? t("withdraw") : t("generate_quote")}
                                  </Button>
                                </div>)}
                            </FORM>
                          </div>
                        </>
                      )}
                    </Formik>
                  </>
                ) : (
                  <div className="add_bank_frame">
                    <div className="add_bank_avater">
                      <Image
                        className="add_bank_img"
                        src={
                          window.location.origin +
                          "/img/phase_1/wallet/add-bank-img.svg"
                        }
                        alt="onboard"
                      />
                    </div>

                    <div className="withdraw-add-other-account-wrap">
                      <Link
                        to="/create-personal-recipient"
                        className="withdraw-add-other-account-area"
                      >
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#4495d3"
                              d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <p>{t("add_bank_account")}</p>
                      </Link>
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div className="crypto_address_card out_space_mx_1_5">
                    {/* <div className="crypto_address_field">
                      <Form.Group className="mb-3">
                          <Form.Label>{t("bank_account.label")}</Form.Label>
                          <Select
                          styles={customStyles}
                          options={recipientList.data.beneficiary_accounts.map(
                            (account, i) => (
                              <option key={i} value={account.id}>
                                {account.first_name} -{" "}
                                {account.account_number}
                              </option>
                            )
                          )}
                          onChange={(e) =>
                            setFieldValue(
                              "stylopay_recipient_id",

                              e.target.value
                            )
                          }
                          placeholder={
                            <div className="placeholder-flex">
                             Select Bank Account
                            </div>
                          }
                        />
                         
                          <ErrorMessage
                            component={"div"}
                            name="stylopay_recipient_id"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        </div> */}
                    <div className="commission-sec">
                      <div className="commission-box m-0">
                        <div className="commission-card">
                          <h5>{t("requested_amount")}:</h5>
                          <h5>
                            {
                              generateQuote.data
                                .requested_amount
                            }{" "}{generateQuote.data.user_wallet.currency_code}
                          </h5>
                        </div>
                        {(generateQuote.data.service_fee + generateQuote.data.commission_amount) > 0 ? <div className="commission-card">
                          <h5>{t("service_fee")}:</h5>
                          <h5>
                            {generateQuote.data.service_fee + generateQuote.data.commission_amount} {" "}{generateQuote.data.user_wallet.currency_code}
                          </h5>
                        </div> : null}
                        <div className="commission-card">
                          <h5>{t("recipient_will_receive")}:</h5>
                          <h5>
                            {
                              generateQuote.data
                                .user_amount
                            }
                            {" "}{generateQuote.data.user_wallet.currency_code}
                          </h5>
                        </div>
                        {generateQuote.data.created_at ? <div className="commission-card">
                          <h5>{t("requested_at")}:</h5>
                          <h5>
                            {generateQuote.data.created_at}
                          </h5>
                        </div> : null}
                      </div>
                    </div>
                  </div>
                  {withdrawalRequest.buttonDisable ||
                    (Object.keys(withdrawalRequest.data).length > 0 &&
                      buttonStepper) ? (
                    <div className="withdraw-stepper">
                      {" "}
                      <ButtonStepper props={withdrawalRequest} />{" "}
                    </div>
                  ) : (
                    <div className="crypto_action item_flex_x_5">
                      <Button
                        className="action-btn overlay w-100"
                        onClick={props.onHide}
                        disabled={
                          withdrawalRequest.buttonDisable ||
                          generateQuote.buttonDisable
                        }
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        className="action-btn overlay w-100"
                        onClick={() => {
                          setId(bank.value);
                          setStep(1);
                        }}
                        disabled={
                          withdrawalRequest.buttonDisable ||
                          generateQuote.buttonDisable
                        }
                      >
                        {t("back")}
                      </Button>
                      <Button
                        onClick={() => handleSubmitWithDrawal(quoteValues)}
                        className="action-btn primary w-100"
                        disabled={withdrawalRequest.buttonDisable}
                      >
                        {t("withdraw")}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <TransactionSuccess
              message="Withdraw Intiated Successfully"
              onHide={onHide}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletWithdrawModal;
