import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { businessOnboardingStart, countriesListStart } from "../../store/actions/UserAction";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import countryList from "react-select-country-list";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { Checkbox, Switch } from "pretty-checkbox-react";
import { useDispatch, useSelector } from "react-redux";
import { countryData } from "../helper/CountryList";
import { ButtonLoader } from "../helper/Loader";
import BusinessFormFields from "../BusinessExchange/BusinessFormFields";
import { countries } from "countries-list";

const BusinessOnboardingModal = (props) => {
  const formRef = useRef(null);
  const t = useTranslation("onboarding");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessOnboarding = useSelector((state) => state.user.businessOnboarding);

  const [selectedGender, setSelectedGender] = useState(null);
  const [step, setStep] = useState(4);
  const [valuesSet, setValuesSet] = useState(1);
  const [value, setValues] = useState(1);
  const [checked, setChecked] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);
  const [selectedDeliveryCountry, setSelectedDeliveryCountry] = useState()

  const [selectedDate, setSelectedDate] = useState(null);
  const [onboarModalShow, setOnboarModalShow] = useState(false);
  const [countriesOption, setCountries] = useState([])

  const countriesList = useSelector((state) => state.user.countriesList);

  const genderOptions = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    {
      label: "Others",
      value: "O",
    },
  ];

  const handleDateChange = (values) => {
    formRef.current.setFieldValue(
      "dob",
      values !== null ? JSON.stringify(new Date(values)).slice(1, 11) : ""
    );
    setSelectedDate(values);
  };

  // const validationSchema = Yup.object().shape({
  //     gender: Yup.string().required(t("required")),
  //     dob: Yup.string()
  //         .required(t("required"))
  //         .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
  //         .test("is-past-date", "date not be future", (value) => {
  //             const dob = new Date(value);
  //             const currentDate = new Date();
  //             return dob <= currentDate;
  //         })
  //         .test("is-18-years-old", t("dob.min_invalid"), (value) => {
  //             const dob = new Date(value);
  //             const currentDate = new Date();
  //             const age = currentDate.getFullYear() - dob.getFullYear();
  //             const monthDiff = currentDate.getMonth() - dob.getMonth();
  //             return (
  //                 age > 18 ||
  //                 (age === 18 && monthDiff > 0) ||
  //                 (age === 18 &&
  //                     monthDiff === 0 &&
  //                     currentDate.getDate() >= dob.getDate())
  //             );
  //         }),

  //     billing_address_1: Yup.string().required(t("required")),
  //     billing_address_2: Yup.string().required(t("required")),
  //     billing_country: Yup.string().required(t("required")),
  //     billing_city: Yup.string().required(t("required")),
  //     billing_state: Yup.string().required(t("required")),
  //     billing_postal_code: Yup.string()
  //         .required(t("required"))
  //         .test("valid-zipcode", t("postal_code.max_invalid"), (value) => {
  //             return value && value.length == 6 ? true : false;
  //         }),
  //     delivery_address_1: Yup.string().required(t("required")),
  //     delivery_address_2: Yup.string().required(t("required")),
  //     delivery_country: Yup.string().required(t("required")),
  //     delivery_city: Yup.string().required(t("required")),
  //     delivery_state: Yup.string().required(t("required")),
  //     delivery_postal_code: Yup.string()
  //         .required(t("required"))
  //         .test("valid-zipcode", t("postal_code.max_invalid"), (value) => {
  //             return value && value.length == 6 ? true : false;
  //         }),
  // });

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required(t("first_name.required")),
    dob: Yup.string()
      .required(t("website_url.required"))
      .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
      .test("is-past-date", "date not be future", (value) => {
        const dob = new Date(value);
        const currentDate = new Date();
        return dob <= currentDate;
      })
      .test("is-18-years-old", "must be 18 year old", (value) => {
        const dob = new Date(value);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - dob.getFullYear();
        const monthDiff = currentDate.getMonth() - dob.getMonth();
        return (
          age > 18 ||
          (age === 18 && monthDiff > 0) ||
          (age === 18 &&
            monthDiff === 0 &&
            currentDate.getDate() >= dob.getDate())
        );
      }),
  });

  const billingAddressSchema = Yup.object().shape({
    billing_address_1: Yup.string()
      .required(t("first_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length >= 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    billing_address_2: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length >= 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    billing_country: Yup.string().required(t("last_name.required")),
    billing_city: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length >= 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    billing_state: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length >= 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    billing_postal_code: Yup.string()
      .required(t("mobile_number.required"))
      .test("valid-zipcode", "Five or six digits only allowed", (value) => {
        return value && (value.length == 6 || value.length == 5) ? true : false;
      }),
  });

  const deliveryAddressSchema = Yup.object().shape({
    delivery_address_1: Yup.string()
      .required(t("first_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length >= 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    delivery_address_2: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length >= 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    delivery_country: Yup.string().required(t("last_name.required")),
    delivery_city: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length >= 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    delivery_state: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length >= 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    delivery_postal_code: Yup.string()
      .required(t("mobile_number.required"))
      .test("valid-zipcode", "Five or six digits only allowed", (value) => {
        return value && (value.length == 6 || value.length == 5) ? true : false;
      }),
  });

  const codeValidationRules = {
    EIN: {
      min: 9,
      max: 9,
      regex: /^\d{9}$/, // Digits only, length is 9
      message: "Invalid EIN code",
    },
    TIN: {
      min: 15,
      max: 15,
      regex: /^[A-Za-z0-9]{15}$/, // Alphanumeric, length 15
      message: "Invalid TIN code",
    },
    UEN: {
      min: 9,
      max: 10,
      regex: /^\d{9,10}$/, // Digits only, length can be 9 or 10
      message: "Invalid UEN code",
    },
    ITN: {
      min: 13,
      max: 13,
      regex: /^\d{13}$/, // Digits only, length is 13
      message: "Invalid ITN code",
    },
    BRN: {
      min: 8,
      max: 8,
      regex: /^\d{8}$/, // Digits only, length is 8
      message: "Invalid BRN code",
    },
    NPWP: {
      min: 16,
      max: 16,
      regex: /^\d{16}$/, // Digits only, length is 16
      message: "Invalid NPWP code",
    },
    IN: {
      min: 11,
      max: 11,
      regex: /^\d{11}$/, // Digits only, length is 11
      message: "Invalid IN code",
    },
  };

  const businessDetailsSchema = Yup.object().shape({
    tax_id_type: Yup.string().required(t("required")),
    tax_id_number: Yup.string().when("tax_id_type", (tax_id_type, schema) => {
      const rule = codeValidationRules[tax_id_type?.toUpperCase()];
      if (rule) {
        const { min, max, regex, message } = rule;
        return Yup.string()
          .required(t("required"))
          // .min(min, message)
          // .max(max, message)
          // .matches(regex, message);
      }
      return schema.required(t("required"));
    }),
    tax_id_issuance_country: Yup.string().required(t("required")),
    foundation_date: Yup.string().required(t("required")),
    tax_id_date_issued: Yup.string().required(t("required")),
    tax_id_date_expiry: Yup.string().required(t("required")),
    company_name: Yup.string()
      .required(t("required"))
      .matches(/^[a-zA-Z\s]+$/, t("company_name.invalid"))
      .min(1, t("company_name.minimum_characters")),
    preferred_name: Yup.string()
      .required(t("required"))
      .matches(/^[a-zA-Z\s]+$/, t("preferred_name.invalid"))
      .min(1, t("required_note")),
    purpose_of_account_opening: Yup.string().required(t("required")),
    source_of_funds: Yup.string().required(t("required")),
  });

  const handleOnboarding = (values) => {
    valuesSet == 1 &&
      formRef.current.setValues({
        ...values,
        tax_id_type: "",
        tax_id_number: "",
        tax_id_issuance_country: "",
        foundation_date: "",
        tax_id_date_issued: "",
        tax_id_date_expiry: "",
        company_name: "",
        preferred_name: "",
        purpose_of_account_opening: "",
        source_of_funds: ""
      });
    step == 3 && valuesSet == 1 &&
      formRef.current.setValues({
        ...values,
        billing_address_1: "",
        billing_address_2: "",
        billing_country: countriesOption?.find((option) => option.value == "AE")?.value,
        billing_state: "",
        billing_city: "",
        billing_postal_code: "",
      });
    step == 4 &&
      value == 1 &&
      formRef.current.setValues({
        ...values,
        delivery_address_1: "",
        delivery_address_2: "",
        delivery_country: countriesOption?.find((option) => option.value == "AE")
          ?.value,
        delivery_state: "",
        delivery_city: "",
        delivery_postal_code: "",
      });
    step == 4 && setValues(0);
    step == 3 && setValuesSet(0);
    step !== 5 && setStep(step + 1);
    step == 5 && dispatch(businessOnboardingStart({
      ...values,
      foundation_date: values.foundation_date.split("-").reverse().join("-"),
      tax_id_date_issued: values.tax_id_date_issued.split("-").reverse().join("-"),
      tax_id_date_expiry: values.tax_id_date_expiry.split("-").reverse().join("-"),
    }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !businessOnboarding.loading &&
      Object.keys(businessOnboarding.data).length > 0
    ) {
      navigate("/");
    }
    setSkipRender(false);
  }, [businessOnboarding]);

  const handleSameBilling = (data) => {
    if (data == false) {
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_address_1: billingAddress.billing_address_1,
        delivery_address_2: billingAddress.billing_address_2,
        delivery_country: billingAddress.billing_country,
        delivery_state: billingAddress.billing_state,
        delivery_city: billingAddress.billing_city,
        delivery_postal_code: billingAddress.billing_postal_code,
      });

      setChecked(!checked);
      setSelectedDeliveryCountry(selectedBillingCountry);
    } else {
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_address_1: "",
        delivery_address_2: "",
        delivery_country: "",
        delivery_state: "",
        delivery_city: "",
        delivery_postal_code: "",
      });

      setChecked(false);
      setSelectedDeliveryCountry(null);
    }
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #e5eaf4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878e96",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
    //   color: state.isFocused || state.isSelected ? "#000" : "#000",
    //   ":hover": {
    //     backgroundColor: "#F9C201",
    //     color: "#000",
    //   },
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#F9C201" 
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected || state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  const allowedPattern = /^[a-zA-Z0-9-.,\/ ]*$/;

  const handleKeyDown = (event) => {
    const { key } = event;
    const keyPressed = event.key;
    if (
      [
        "Backspace",
        "Tab",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Delete",
      ].includes(key)
    ) {
      return;
    }
    if (!allowedPattern.test(keyPressed) && keyPressed.length === 1) {
      event.preventDefault();
    }
  };

  const taxIdOptions = [
    { label: "EIN", value: "ein" },
    { label: "TIN", value: "tin" },
    { label: "UEN", value: "uen" },
    { label: "ITN", value: "itn" },
    { label: "BRN", value: "brn" },
    { label: "NPWP", value: "npwp" },
    { label: "IN", value: "in" },
  ];

  const transactionOptions = [
    { label: "Cash Deposit", value: "cash_deposit" },
    { label: "Cash Withdrawal", value: "cash_withdrawal" },
    { label: "Forex", value: "forex" },
    { label: "Loan Payments", value: "loan_payments" },
    { label: "Online Payment", value: "online_payment" },
    { label: "Others", value: "others" },
    { label: "Wire Transfers", value: "wire_transfers" },
  ];

  const fundingOptions = [
    { label: "Debt", value: "debt" },
    { label: "Debt Capital", value: "debt_capital" },
    { label: "Directors' Capital", value: "directors_capital" },
    { label: "Equity Capital", value: "equity_capital" },
    { label: "Others", value: "others" },
    { label: "Term Loan", value: "term_loan" },
    { label: "Venture Funding", value: "venture_funding" },
  ];

  const countryOptions = Object.keys(countries)
    .filter(
      (country) =>
        ![
          "AF",
          "BY",
          "CF",
          "CN",
          "CU",
          "CD",
          "ET",
          "IN",
          "IR",
          "IQ",
          "LY",
          "ML",
          "NI",
          "KP",
          "SO",
          "SS",
          "SD",
          "SY",
          "US",
          "VE",
          "YE",
          "ZW",
        ].includes(country)
    )
    .map((alpha2) => ({
      value: alpha2,
      label: countries[alpha2].name,
    }));

  useEffect(() => {
    if (!skipRender && !countriesList.buttonDisable && Object.keys(countriesList.data).length > 0) {
      if (countriesList.data.total_countries > 0) {
        setCountries(countriesList.data.countries.map((data) => ({ label: data.country_name, value: data.alpha_2_code })))
        setSelectedBillingCountry(countriesList.data.countries?.find((option) => option.value == "AE"))
        setSelectedDeliveryCountry(countriesList.data.countries?.find((option) => option.value == "AE"))
      }
    }
    setSkipRender(false);
  }, [countriesList])

  useEffect(() => {
    dispatch(countriesListStart({ all: 1 }))
  }, []);



  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="onboard_modal"
        backdrop="static"
        scrollable="true"
      >
        <Modal.Body>
          <>
            <div className="onboard_form_wrapped">
              <div className="onboard_form_titles item_line_between ">
                <h2>{t("onboarding")}</h2>
                <Button
                  className="action-btn link-btn"
                  onClick={() => navigate("/")}
                >
                  Skip
                </Button>
              </div>
              <div className="board-form-frame">
                <Formik
                  initialValues={{
                    gender: "",
                    dob: "",
                  }}
                  validationSchema={
                    step == 2
                      ? validationSchema
                      : step == 3
                        ? businessDetailsSchema
                        : step == 4 ?
                          billingAddressSchema
                          : deliveryAddressSchema
                  }
                  onSubmit={handleOnboarding}
                  innerRef={formRef}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <FORM className="create-recipient-form">
                      <div className="borad-accordian-wrapped ">
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>Personal Details</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${step == 2 ? "active" : ""
                              }`}
                          >
                            <Row>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("gender")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    options={genderOptions}
                                    styles={customStyles}
                                    value={selectedGender}
                                    onChange={(data) => {
                                      setSelectedGender(data);
                                      data !== null &&
                                        setFieldValue("gender", data.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="gender"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label className="w-100">
                                    {t("dob.name")} <span>*</span>
                                  </Form.Label>
                                  <DatePicker
                                    value={selectedDate}
                                    render={<InputIcon placeholder="Select DOB"/>}
                                    minDate={
                                      new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() - 60
                                        )
                                      )
                                    }
                                    maxDate={
                                      new Date(
                                        new Date().setHours(23, 59, 59, 999)
                                      )
                                    }
                                    onChange={handleDateChange}
                                    format="DD/MM/YYYY"
                                    multiple={false}
                                    placeholder="Select DOB"
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="dob"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>

                            
                            </Row>
                            

                            <div className="board-accordian-action">
                              <Button
                                className="action-btn primary"
                                type="submit"
                              >
                                {t("next")}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>Business Details</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${step == 3 ? "active" : ""
                              }`}
                          >
                            <Row>
                              <Col md={6}>
                                <div className="business-from-card">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label">
                                      {t("company_name.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      type="text"
                                      placeholder={t(
                                        "company_name.placeholder"
                                      )}
                                      aria-describedby="text-addon"
                                      name="company_name"
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      name="company_name"
                                      component="div"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("tax_id_type.name")} <span>*</span>
                                    </Form.Label>
                                    <Select
                                      options={taxIdOptions}
                                      styles={customStyles}
                                      classNamePrefix="react-select"
                                      onChange={(selectedOption) =>
                                        setFieldValue(
                                          "tax_id_type",
                                          selectedOption.value
                                        )
                                      }
                                      value={taxIdOptions.find(
                                        (option) =>
                                          option.value === values.tax_id_type
                                      )}
                                      placeholder={t("tax_id_type.placeholder")}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="tax_id_type"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("registered_date.name")} <span>*</span>
                                    </Form.Label>
                                    <div className="single-date-picker">
                                      <InputGroup className="mb-0">
                                        <DatePicker
                                          minDate={new Date("1900-01-01")}
                                          maxDate={
                                            new Date(
                                              new Date().setHours(
                                                23,
                                                59,
                                                59,
                                                999
                                              )
                                            )
                                          }
                                          value={values.foundation_date}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "foundation_date",
                                              e
                                                ? `${e.day < 10
                                                  ? "0" + e.day
                                                  : e.day
                                                }-${e.month < 10
                                                  ? "0" + e.month
                                                  : e.month
                                                }-${e.year}`
                                                : ""
                                            )
                                          }
                                          placeholder={t(
                                            "registered_date.placeholder"
                                          )}
                                          width="100%"
                                          format="DD-MM-YYYY"
                                          id="date-picker-input"
                                          editable={false}
                                          onOpenPickNewDate={false}
                                          popperPlacement="Bottom"
                                          render={<InputIcon placeholder="Select Date"/>}
                                        />
                                      </InputGroup>
                                    </div>
                                    <ErrorMessage
                                      component={"div"}
                                      name="foundation_date"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("tax_id_date_issued.name")}{" "}
                                      <span>*</span>
                                    </Form.Label>
                                    <div className="single-date-picker">
                                      <InputGroup className="mb-0">
                                        <DatePicker
                                          minDate={new Date("1900-01-01")}
                                          maxDate={
                                            new Date(
                                              new Date().setHours(
                                                23,
                                                59,
                                                59,
                                                999
                                              )
                                            )
                                          }
                                          value={values.tax_id_date_issued}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "tax_id_date_issued",
                                              e
                                                ? `${e.day < 10
                                                  ? "0" + e.day
                                                  : e.day
                                                }-${e.month < 10
                                                  ? "0" + e.month
                                                  : e.month
                                                }-${e.year}`
                                                : ""
                                            )
                                          }
                                          placeholder={t(
                                            "tax_id_date_issued.placeholder"
                                          )}
                                          width="100%"
                                          format="DD-MM-YYYY"
                                          id="date-picker-input-1"
                                          editable={false}
                                          onOpenPickNewDate={false}
                                          popperPlacement="Bottom"
                                          render={<InputIcon placeholder="Select Date"/>}
                                        />
                                      </InputGroup>
                                    </div>
                                    <ErrorMessage
                                      component={"div"}
                                      name="tax_id_date_issued"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("purpose_of_account_opening.name")}{" "}
                                      <span>*</span>
                                    </Form.Label>
                                    <Select
                                      options={transactionOptions}
                                      styles={customStyles}
                                      classNamePrefix="react-select"
                                      onChange={(selectedOption) =>
                                        setFieldValue(
                                          "purpose_of_account_opening",
                                          selectedOption.value
                                        )
                                      }
                                      value={transactionOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.purpose_of_account_opening
                                      )}
                                      placeholder={t(
                                        "purpose_of_account_opening.placeholder"
                                      )}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="purpose_of_account_opening"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="business-from-card">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label">
                                      {t("preferred_name.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      type="text"
                                      placeholder={t(
                                        "preferred_name.placeholder"
                                      )}
                                      aria-describedby="text-addon"
                                      name="preferred_name"
                                      maxLength={20}
                                    />
                                    <ErrorMessage
                                      name="preferred_name"
                                      component="div"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label">
                                      {t("tax_id_number.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      type={
                                        values.tax_id_type == "tin"
                                          ? "text"
                                          : "number"
                                      }
                                      placeholder={t(
                                        "tax_id_number.placeholder"
                                      )}
                                      aria-describedby="text-addon"
                                      name="tax_id_number"
                                    />
                                    <ErrorMessage
                                      name="tax_id_number"
                                      component="div"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("tax_id_issuance_country.name")}{" "}
                                      <span>*</span>
                                    </Form.Label>
                                    <Select
                                      options={countryOptions}
                                      styles={customStyles}
                                      classNamePrefix="react-select"
                                      onChange={(selectedOption) =>
                                        setFieldValue(
                                          "tax_id_issuance_country",
                                          // iso.whereAlpha2(selectedOption.value).alpha3
                                          selectedOption.value
                                        )
                                      }
                                      value={countryOptions.find((option) =>
                                        option.value ===
                                          values.tax_id_issuance_country
                                          ? // ? iso.whereAlpha3(values.tax_id_issuance_country)
                                          //   ?.alpha2
                                          values.tax_id_issuance_country
                                          : ""
                                      )}
                                      placeholder={t(
                                        "tax_id_issuance_country.placeholder"
                                      )}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="tax_id_issuance_country"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("tax_id_date_expiry.name")}{" "}
                                      <span>*</span>
                                    </Form.Label>
                                    <div className="single-date-picker">
                                      <InputGroup className="mb-0">
                                        <DatePicker
                                          minDate={new Date()}
                                          // maxDate={new Date()}
                                          value={values.tax_id_date_expiry}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "tax_id_date_expiry",
                                              e
                                                ? `${e.day < 10
                                                  ? "0" + e.day
                                                  : e.day
                                                }-${e.month < 10
                                                  ? "0" + e.month
                                                  : e.month
                                                }-${e.year}`
                                                : ""
                                            )
                                          }
                                          placeholder={t(
                                            "tax_id_date_expiry.placeholder"
                                          )}
                                          width="100%"
                                          format="DD-MM-YYYY"
                                          id="date-picker-input-2"
                                          popperPlacement="Bottom"
                                          render={<InputIcon placeholder="Select Date"/>}
                                        />
                                      </InputGroup>
                                    </div>
                                    <ErrorMessage
                                      component={"div"}
                                      name="tax_id_date_expiry"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {t("source_of_funds.name")} <span>*</span>
                                    </Form.Label>
                                    <Select
                                      options={fundingOptions}
                                      styles={customStyles}
                                      classNamePrefix="react-select"
                                      onChange={(selectedOption) =>
                                        setFieldValue(
                                          "source_of_funds",
                                          selectedOption.value
                                        )
                                      }
                                      value={fundingOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.source_of_funds
                                      )}
                                      placeholder={t(
                                        "source_of_funds.placeholder"
                                      )}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="source_of_funds"
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                            </Row>
                            {/* <BusinessFormFields/> */}
                            <div className="board-accordian-action">
                              <Button
                                className="action-btn secondary"
                                onClick={() => {
                                  setStep(step - 1);
                                }}
                              >
                                {t("back")}
                              </Button>
                              <Button
                                className="action-btn primary"
                                type="submit"
                              // type="button"
                              // onClick={()=> setStep(step + 1)}
                              >
                                {t("next")}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>{t("billing_heading")}</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${step == 4 ? "active" : ""
                              }`}
                          >
                            <Row>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_address.name")} 1 <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="billing_address_1"
                                    type="text"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_address_1",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={handleKeyDown}
                                    placeholder={`${t(
                                      "billing_address.placeholder"
                                    )} 1`}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_address_1"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_address.name")} 2 <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="billing_address_2"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_address_2",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={handleKeyDown}
                                    type="text"
                                    placeholder={`${t(
                                      "billing_address.placeholder"
                                    )} 2`}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_address_2"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_city.name")} <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="billing_city"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          (e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")) ||
                                          e.code == "Space"
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_city",
                                        e.target.value
                                      );
                                    }}
                                    placeholder={t("billing_city.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_city"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_state.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="billing_state"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          (e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")) ||
                                          e.code == "Space"
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_state",
                                        e.target.value
                                      );
                                    }}
                                    placeholder={t("billing_state.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_state"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_country")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    value={selectedBillingCountry}
                                    options={countriesOption}
                                    styles={customStyles}
                                    onChange={(country) => {
                                      setChecked(false);
                                      setSelectedBillingCountry(country);
                                      setFieldValue(
                                        "billing_country",
                                        country.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_country"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_zip_code.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name="billing_postal_code"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_postal_code",
                                        e.target.value
                                      );
                                    }}
                                    placeholder={t(
                                      "billing_zip_code.placeholder"
                                    )}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_postal_code"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="board-accordian-action">
                              <Button
                                className="action-btn secondary"
                                onClick={() => {
                                  setStep(step - 1);
                                }}
                              >
                                {t("back")}
                              </Button>
                              <Button
                                className="action-btn primary"
                                type="submit"
                              >
                                {t("next")}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>{t("delivery_heading")}</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${step == 5 ? "active" : ""
                              }`}
                          >
                            <Row className="justify-content-end">
                              <Col lg={6}>
                                <div className="mb-3 text-end">
                                  <Checkbox
                                    onClick={() => {
                                      handleSameBilling(checked);
                                    }}
                                    checked={checked}
                                  >
                                    {t("same_billing_details")}
                                  </Checkbox>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_address.name")} 1{" "}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="delivery_address_1"
                                    type="text"
                                    placeholder={`${t(
                                      "delivery_address.placeholder"
                                    )} 1`}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_address_1",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={handleKeyDown}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_address_1"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_address.name")} 2{" "}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="delivery_address_2"
                                    type="text"
                                    placeholder={`${t(
                                      "delivery_address.placeholder"
                                    )} 2`}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_address_2",
                                        e.target.value
                                      );
                                    }}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_address_2"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_city.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_city",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          (e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")) ||
                                          e.code == "Space"
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    name="delivery_city"
                                    placeholder={t("delivery_city.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_city"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_state.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="delivery_state"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_state",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        (!/[A-Z]|[a-z]|Backspace||Tab/.test(
                                          e.key
                                        ) &&
                                          !(
                                            e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")
                                          )) ||
                                        e.code == "Space"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={t(
                                      "delivery_state.placeholder"
                                    )}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_state"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_country")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    value={selectedDeliveryCountry}
                                    styles={customStyles}
                                    options={countriesOption}
                                    onChange={(country) => {
                                      setChecked(false);
                                      setSelectedDeliveryCountry(country);
                                      setFieldValue(
                                        "delivery_country",
                                        country.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_country"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_zip_code.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_postal_code",
                                        e.target.value
                                      );
                                    }}
                                    name="delivery_postal_code"
                                    placeholder={t(
                                      "delivery_zip_code.placeholder"
                                    )}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_postal_code"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="onboarding-action-btn-sec">
                              <div>
                                <Button
                                  className="action-btn  overlay"
                                  onClick={() => navigate("/")}
                                >
                                  {t("cancel")}
                                </Button>
                              </div>
                              <div>
                                <div className="board-accordian-action">
                                  <Button
                                    className="action-btn secondary"
                                    onClick={() => setStep(step - 1)}
                                  >
                                    {t("back")}
                                  </Button>
                                  <Button
                                    className="action-btn primary"
                                    type="submit"
                                    disabled={businessOnboarding.buttonDisable}
                                  >
                                    {businessOnboarding.buttonDisable ? (
                                      <ButtonLoader />
                                    ) : (
                                      t("onboard")
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessOnboardingModal;
