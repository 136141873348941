import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const RiskDisclosurePolicy = () => {
    return (
        <>
            <Helmet>
                <script
                    src='//in.fw-cdn.com/32218506/1186004.js'
                    chat='true'>
                </script>
            </Helmet>
            <div className="static-pag-sec">
                <div className="static-page-header">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col md="9">
                                <div className="static-section-title">
                                    <h1>Risk Disclosure Policy</h1>
                                    <h4>Effective Date: October 8, 2024</h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <div className="static-box">
                        <Row>
                            <Col md={12}>
                                <div className="static-card">
                                    <h4>Risk Disclosure Policy</h4>
                                    <h4>1. Introduction</h4>
                                    <p>This Risk Disclosure Policy ("Policy") is designed to inform you about the inherent risks associated with trading cryptocurrencies on HeraldEX ("we," "us," or "our"). By using our platform, you acknowledge that you have read and understood this Policy and accept the risks involved.</p>

                                    <h4>2. Nature of Cryptocurrency Trading</h4>
                                    <p>Cryptocurrency trading is subject to significant volatility and price fluctuations. The value of cryptocurrencies can change dramatically over short period, leading to potential losses.</p>


                                    <h4>3. Market Risk</h4>
                                    <ul>
                                        <li><strong>Volatility:</strong> Cryptocurrencies are known for their price volatility. Prices can fluctuate widely within a short time frame, leading to substantial gains or losses.</li>
                                        <li><strong>Liquidity Risk:</strong> Some cryptocurrencies may not have enough liquidity, making it difficult to buy or sell at desired prices.</li>
                                    </ul>

                                    <h4>4. Regulatory Risk</h4>
                                    <ul>
                                        <li><strong>Changes in Regulations:</strong> Cryptocurrency markets are evolving, and regulatory changes may impact the legality, accessibility, and value of certain cryptocurrencies. You should be aware of the legal implications of trading in your jurisdiction.</li>
                                    </ul>

                                    <h4>5. Technology Risk</h4>
                                    <ul>
                                        <li><strong>System Failures:</strong> Our platform relies on technology that may be subject to failures, outages, or disruptions. While we strive to maintain reliable services, we cannot guarantee uninterrupted access to our platform.</li>
                                        <li><strong>Cybersecurity Risks:</strong> Cryptocurrency exchanges are targets for cyberattacks. We implement security measures, but there is no guarantee against unauthorized access, hacking, or theft of your assets.</li>
                                    </ul>

                                    <h4>6. Trading Risk</h4>
                                    <ul>
                                        <li><strong>Margin Trading:</strong> If you engage in margin trading, you may face additional risks, including the potential for greater losses than your initial investment.</li>
                                        <li><strong>Lack of Experience:</strong> Cryptocurrency trading can be complex. If you are a novice trader, you may not fully understand the risks and may incur unexpected losses.</li>
                                    </ul>

                                    <h4>7. No Guaranteed Profits</h4>
                                    <p>There is no guarantee of profit when trading cryptocurrencies. You should only invest what you can afford to lose and consider your risk tolerance before engaging in trading activities.</p>

                                    <h4>8. Risk Management</h4>
                                    <p>We encourage you to implement risk management strategies, such as setting stop-loss orders and diversifying your portfolio, to mitigate potential losses.</p>

                                    <h4>9. Acknowledgment</h4>
                                    <p>By using our platform, you acknowledge that you have read and understood this Risk Disclosure Policy and accept the risks associated with cryptocurrency trading.</p>

                                    <h4>10. Contact Us</h4>
                                    <p>If you have any questions or concerns regarding this Policy or your trading activities, please contact us at: <a href="mailto:support@herald.exchange">support@herald.exchange</a>.</p>

                                    <h4>7. No Guaranteed Profits</h4>
                                    <p>There is no guarantee of profit when trading cryptocurrencies. You should only invest what you can afford to lose and consider your risk tolerance before engaging in trading activities.</p>

                                    <h4>8. Risk Management</h4>
                                    <p>We encourage you to implement risk management strategies, such as setting stop-loss orders and diversifying your portfolio, to mitigate potential losses.</p>

                                    <h4>9. Acknowledgment</h4>
                                    <p>By using our platform, you acknowledge that you have read and understood this Risk Disclosure Policy and accept the risks associated with cryptocurrency trading.</p>

                                    <h4>10. Contact Us</h4>
                                    <p>If you have any questions or concerns regarding this Policy or your trading activities, please contact us at: <a href="mailto:support@herald.exchange">support@herald.exchange</a>.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default RiskDisclosurePolicy