import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const TermAndCondition = () => {
  return (
    <>
      <Helmet>
        <script
          src='//in.fw-cdn.com/32218506/1186004.js'
          chat='true'>
        </script>
      </Helmet>
      <div className="static-pag-sec">
        <div className="static-page-header">
          <Container fluid>
            <Row className="justify-content-center">
              <Col md="9">
                <div className="static-section-title">
                  <h1>Terms and Conditions</h1>
                  <h4>Effective Date: 09/10/2024</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <div className="static-box">
            <Row>
              <Col md={12}>
                <div className="static-card">
                  <p>Welcome to HeraldEX, A service provided by Herald Exchange ("we," "us," "our"). By accessing or using our platform (the "Service"), you agree to comply with and be bound by these Terms and Conditions ("Terms"). Please review these Terms carefully before using our website, <a href="https://herald.exchange/">https://herald.exchange/</a> (the "Site").</p>

                  <h4>1. Acceptance of Terms</h4>
                  <p>By accessing or signing up for HeraldEX, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree, you must not use our Service.</p>

                  <h4>2. Eligibility</h4>
                  <p>You must be at least 18 years old to use this platform. By using the Service, you represent and warrant that you meet this requirement and that all registration information you submit is accurate and truthful.</p>

                  <h4>3. Account Registration</h4>
                  <ul>
                    <li>To access the service, you must create an account with HeraldEX.</li>
                    <li>You are responsible for maintaining the confidentiality of your account and password and you are fully accountable for all activities carried out under your account.</li>
                    <li>You agree to notify us immediately of any unauthorized account use or security breaches.</li>
                  </ul>

                  <h4>4. Cryptocurrency Exchange Services</h4>
                  <ul>
                    <li>HeraldEX allows users to exchange cryptocurrencies between different currencies, convert real money into crypto, and crypto back into real money.</li>
                    <li>All transactions are subject to market conditions, and HeraldEX does not guarantee the value or stability of any cryptocurrency.</li>
                    <li>We are not responsible for any losses or financial damages resulting from transactions performed on the platform.</li>
                  </ul>

                  <h4>5. Fees and Charges</h4>
                  <ul>
                    <li>We may charge fees for certain transactions, such as converting currencies or withdrawing funds. These fees will be clearly displayed at the time of the transaction.</li>
                    <li>You agree to pay all fees and charges associated with the use of the Service.</li>
                  </ul>

                  <h4>6. Risk Disclaimer</h4>
                  <ul>
                    <li>Cryptocurrency transactions involve significant risk, including market volatility and regulatory uncertainty. You acknowledge that by using our Service, you are fully aware of these risks and assume all responsibility for your actions.</li>
                    <li>HeraldEX does not provide financial or investment advice. You should conduct your own research and consult with a financial professional before making any investment decisions.</li>
                  </ul>

                  <h4>7. Prohibited Activities</h4>
                  <ul>
                    <li>Violating any laws, regulations, or our policies.</li>
                    <li>Using the Service for any unlawful or fraudulent purposes.</li>
                    <li>Attempting to interfere with the proper functioning of the platform.</li>
                    <li>Providing false or misleading information.</li>
                  </ul>

                  <h4>8. Intellectual Property</h4>
                  <p>All content, trademarks, logos, and intellectual property displayed on the Site are the exclusive property of HeraldEX or its licensors. You may not use, reproduce, or distribute any content without our express permission.</p>

                  <h4>9. Termination of Service</h4>
                  <p>We reserve the right to terminate or suspend your account and access to the Service at our sole discretion, without notice, for any reason, including but not limited to violating these Terms.</p>

                  <h4>10. Limitation of Liability</h4>
                  <p>To the maximum extent permitted by law, HeraldEX will not be held liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or use, that arise from or are related to your use or inability to use the Service.</p>

                  <h4>11. Changes to the Terms</h4>
                  <p>We may revise these Terms from time to time at our sole discretion. The most current version will always be available on our Site. Your continued use of the Service following any changes constitutes your acceptance of the updated Terms.</p>

                  <h4>Contact Information</h4>
                  <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@herald.exchange">support@herald.exchange</a>.</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default TermAndCondition