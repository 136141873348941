import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Image, Table, Button } from "react-bootstrap";
import Select from "react-select";
import CopyToClipboard from "react-copy-to-clipboard";
import Skeleton from "react-loading-skeleton";
import { connect, useDispatch, useSelector } from "react-redux";
import NoDataFound from "../helper/NoDataFound";
import { fetchWithDrawalsStart } from "../../store/actions/WalletAction";
import * as Yup from "yup";
import { Field, Form as FORM, Formik } from "formik";
import {
  userWithdrawalsExportStart,
  withdrawalStatusStart,
} from "../../store/actions/BuyAction";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../helper/Pagination";
import { useTranslation } from "react-multi-lang";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/ToastNotification";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import dayjs from "dayjs";
import WithdrawTransactionModal from "../WithDraw/WithdrawTransactionModal";
import WithdrawTransactionViewModal from "./TransactionModals/WithdrawTransactionViewModal";
import NoTransactionData from "../helper/NoTransactionData";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const WithDrawTransaction = (props) => {
  const params = useParams();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const t = useTranslation("wallet_index.withdraw_table");
  const [transactionId, setTransactionId] = useState("");

  const options = [
    { value: "0", label: t("pending") },
    { value: "1", label: t("success") },
    { value: "3", label: t("failed") },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      width: "150px",
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      width: "150px",
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #e5eaf4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "48px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878e96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#878e96",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
    //   color: state.isFocused || state.isSelected ? "#000" : "#000",
    //   ":hover": {
    //     backgroundColor: "#F9C201",
    //     color: "#000",
    //   },
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#F9C201" 
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected || state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  const [modalShow, setModalShow] = useState(false);
  const [withdrawalId, setWithdrawalId] = useState(null);
  const dispatch = useDispatch();
  const withDrawals = useSelector((state) => state.wallet.withDrawals);
  const withdrawalStatus = useSelector((state) => state.buy.withdrawalStatus);
  const [value, setValue] = useState([null, null]);
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
    from_date: "",
    to_date: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const calenderRefs = useRef(null);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(
      fetchWithDrawalsStart({
        ...filter,
        skip: 0,
        take: 12,
      })
    );
  }, [filter, page]);

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11)
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11)
          : ""
      );
      setDateValues({
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11)
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11)
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      });
    }
  };

  const handleExport = () => {
    dispatch(
      userWithdrawalsExportStart({
        ...filter,
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !withdrawalStatus.loading &&
      Object.keys(withdrawalStatus.data).length > 0
    ) {
      dispatch(
        fetchWithDrawalsStart({
          ...filter,
          skip: 0,
          take: 12,
        })
      );
    }
  }, [withdrawalStatus]);

  const handlePageClick = ({ selected }) => {
    let param = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/transactions?${param}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.userWithdrawalsExport.buttonDisable &&
      Object.keys(props.userWithdrawalsExport.data).length > 0
    ) {
      window.open(props.userWithdrawalsExport.data.url, "_blank", "noreferrer");
    }
    setSkipRender(false);
  }, [props.userWithdrawalsExport]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  const cancelWithdrawal = (accout_id) => {
    setWithdrawalId(accout_id);
    setModalShow(true);
  };

  const handleRefresh = (values) => {
    setWithdrawalId(values.id);
    dispatch(
      withdrawalStatusStart({ user_withdrawal_id: values.user_withdrawal_id })
    );
  };

  return (
    <>
      <div className="wallet_table_head item_line_between   out_space_my_1_5">
        {(filter.status !== "" ||
          filter.search_key !== "" ||
          filter.from_date !== "" ||
          filter.to_date !== "" ||
          (Object.keys(withDrawals.data).length > 0 &&
            withDrawals.data.total_user_withdrawals > 0 &&
            filter.status == "" &&
            filter.search_key == "" &&
            filter.from_date == "" &&
            filter.to_date == "")) && (
          <>
            <div className="table_head_titles">
              <h3>Withdraw History</h3>
              <h5 className="wallet_count item_center">
                {withDrawals.data.total_user_withdrawals}
              </h5>
            </div>
            <div className="table_head_action">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                  sort_by: searchParams.get("sort_by")
                    ? searchParams.get("sort_by")
                    : filter.sort_by,
                  from_date: "",
                  to_date: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                    from_date: dateValues.from_date,
                    to_date: dateValues.to_date,
                  });

                  navigate(
                    searchParams.get("sort_by")
                      ? `/transactions?search_key=${
                          val.search_key
                        }&sort_by=${searchParams.get("sort_by")}`
                      : `/transactions?search_key=${val.search_key}`,
                    { state: { value: value } }
                  );
                }}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm, submitForm }) => (
                  <FORM>
                    <div className="wallet_sort_form ">
                      <div className="wallet_sort_search">
                        <InputGroup className="mb-0">
                          <Field
                            name="search_key"
                            onChange={(e) => {
                              {
                                e.target.value == "" && submitForm();
                                formRef.current.setFieldValue(
                                  "search_key",
                                  e.target.value
                                );
                              }
                            }}
                            placeholder={t("search")}
                            className="form-control"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                formRef.current.handleSubmit();
                              }
                            }}
                          />
                          <InputGroup.Text id="basic-addon2">
                            {searchParams.get("search_key") ? (
                              <Button
                                type="button"
                                className="action-btn link-btn"
                                onClick={() => {
                                  setFieldValue("search_key", "");
                                  setFilter({
                                    ...filter,
                                    search_key: "",
                                    from_date: dateValues.from_date,
                                    to_date: dateValues.to_date,
                                  });
                                  navigate(
                                    searchParams.get("sort_by")
                                      ? `/transactions?sort_by=${searchParams.get(
                                          "sort_by"
                                        )}`
                                      : `/transactions`
                                  );
                                }}
                              >
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#111"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                className="action-btn link-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#171717"
                                    d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75zM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z"
                                  ></path>
                                </svg>
                              </Button>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="wallet_sort_date">
                        <Form.Group>
                          <InputGroup className="mb-0">
                            <DatePicker
                              format="DD/MM/YYYY"
                              value={value}
                              style={{ padding: "1em" }}
                              placeholder="Select Date Range"
                              onChange={(date) => {
                                handleDateChange(date);
                              }}
                              range
                              numberOfMonths={2}
                              ref={calenderRefs}
                              maxDate={
                                new Date(new Date().setHours(23, 59, 59, 999))
                              }
                              editable={false}
                              onOpenPickNewDate={false}
                              render={!close && <InputIcon  placeholder="Select Date Range"/>}
                            >
                              <div
                                className="d-flex justify-content-end"
                                style={{ padding: "1em", gap: "0.5em" }}
                              >
                                <Button
                                  className="action-btn overlay"
                                  onClick={() => {
                                    calenderRefs.current.closeCalendar();
                                    setValue([null, null]);
                                  }}
                                >
                                  {t("close")}
                                </Button>
                                <Button
                                  className="action-btn overlay"
                                  onClick={() => {
                                    if (values.from_date || values.to_date) {
                                      setFilter({
                                        ...filter,
                                        skip: 12 * (page - 1),
                                        take: 12,
                                        from_date: dateValues.from_date,
                                        to_date: dateValues.to_date,
                                      });
                                      calenderRefs.current.closeCalendar();
                                      setClose(true);
                                    } else
                                      getErrorNotificationMessage(
                                        t("please_select_date")
                                      );
                                  }}
                                >
                                  {t("apply")}
                                </Button>
                              </div>
                            </DatePicker>
                            {close && (
                              <>
                                <button
                                  type="button"
                                  className=" date-cancel"
                                  onClick={() => {
                                    setDateValues({
                                      from_date: "",
                                      to_date: "",
                                    });
                                    setValue([null, null]);
                                    setClose(false);
                                    setFilter({
                                      ...filter,
                                      from_date: "",
                                      to_date: "",
                                    });
                                  }}
                                >
                                  <svg
                                    height="20"
                                    width="20"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    class="css-tj5bde-Svg"
                                  >
                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                  </svg>
                                </button>
                              </>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </div>
                      <div className="wallet_sort_filter">
                        <Select
                          className="fillert-drop"
                          options={options}
                          isClearable
                          styles={customStyles}
                          isSearchable={false}
                          value={filterStatus || null}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setFilter({
                                ...filter,
                                status: selectedOption.value,
                              });
                              setFilterStatus(selectedOption);
                              navigate(
                                filter.search_key
                                  ? `/transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                                  : `/transactions?status=${selectedOption.value}`
                              );
                            } else {
                              const newFilter = { ...filter };
                              delete newFilter.status;
                              setFilter(newFilter);

                              navigate(
                                filter.search_key
                                  ? `/transactions?search_key=${filter.search_key}`
                                  : `/transactions`
                              );
                              setFilterStatus(null);
                            }
                          }}
                          placeholder={
                            <div className="placeholder-flex">
                              Status
                            </div>
                          }
                        />
                      </div>
                      <div className="wallet_sort_export">
                        <Button
                          type="submit"
                          className="action-btn gradient"
                          onClick={handleExport}
                          disabled={
                            props.userWithdrawalsExport.buttonDisable ||
                            withDrawals.loading ||
                            !Object.keys(withDrawals.data).length > 0 ||
                            !withDrawals.data.user_withdrawals.length > 0
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#171717"
                              d="M11.88 14.99c-.19 0-.38-.07-.53-.22l-2.56-2.56a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.03 2.03 2.03-2.03c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.56 2.56c-.15.15-.34.22-.53.22z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M11.88 14.92c-.41 0-.75-.34-.75-.75V4c0-.41.34-.75.75-.75s.75.34.75.75v10.17c0 .41-.34.75-.75.75z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M12 20.93c-5.15 0-8.75-3.6-8.75-8.75 0-.41.34-.75.75-.75s.75.34.75.75c0 4.27 2.98 7.25 7.25 7.25s7.25-2.98 7.25-7.25c0-.41.34-.75.75-.75s.75.34.75.75c0 5.15-3.6 8.75-8.75 8.75z"
                            ></path>
                          </svg>
                          <span>{t("buy_transaction.export")}</span>
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>

      {withDrawals.loading ? (
        <Skeleton count={4} height={80} />
      ) : !skipRender &&
        Object.keys(withDrawals.data).length > 0 &&
        withDrawals.data.user_withdrawals.length > 0 ? (
        <React.Fragment>
          <div className="wallet_table">
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    {t("wallet_table.transaction_hash")} /
                    {t("wallet_table.transaction_id")}
                  </th>
                  <th>{t("wallet_table.amount")}</th>
                  <th>
                    {t("withdraw_table.wallet_address")} /
                    {t("withdraw_table.billing_account")}
                  </th>
                  <th>{t("wallet_table.status")}</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {withDrawals.data.user_withdrawals.map((withdrawals, key) => (
                  <tr key={key}>
                    <td>
                      <span className="wallet_shuffle_icon item_center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <g fill="#000" fillRule="evenodd" clipRule="evenodd">
                            <path d="M15 3a1 1 0 011-1h5a1 1 0 011 1v5a1 1 0 11-2 0V4h-4a1 1 0 01-1-1z"></path>
                            <path d="M21.707 2.293a1 1 0 010 1.414l-17 17a1 1 0 01-1.414-1.414l17-17a1 1 0 011.414 0zM21 15a1 1 0 011 1v5a1 1 0 01-1 1h-5a1 1 0 110-2h4v-4a1 1 0 011-1z"></path>
                            <path d="M14.293 14.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414zM3.293 3.293a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414z"></path>
                          </g>
                        </svg>
                      </span>
                    </td>

                    <td>
                      {withdrawals.withdraw_type !== "crypto" ? (
                        withdrawals.unique_id ? (
                          <>
                            <h5 className="table_code">
                              <span>{withdrawals.unique_id}</span>
                              <CopyToClipboard
                                text={withdrawals?.unique_id}
                                onCopy={() =>
                                  getSuccessNotificationMessage(
                                    withdrawals.withdraw_type == "crypto"
                                      ? t(
                                          "withdraw_table.transaction_hash_copied_to_clipboard"
                                        )
                                      : t(
                                          "withdraw_table.transaction_id_copied_to_clipboard"
                                        )
                                  )
                                }
                              >
                                <Button className="action-btn copy-btn item_center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h5>
                            <p className="table_amount">
                              {withdrawals.created_at}
                            </p>
                          </>
                        ) : (
                          t("n_a")
                        )
                      ) : withdrawals.payment_id ||
                        withdrawals.stylopay_withdrawal_transaction_id ? (
                        <>
                          <h5 className="table_code">
                            <span>
                              {withdrawals.payment_id !== ""
                                ? ` ${withdrawals.payment_id.slice(
                                    0,
                                    5
                                  )}.... ${withdrawals.payment_id.slice(-5)}`
                                : withdrawals.stylopay_withdrawal_transaction_id !==
                                    "" &&
                                  ` ${withdrawals.stylopay_withdrawal_transaction_id.slice(
                                    0,
                                    5
                                  )}....${withdrawals.stylopay_withdrawal_transaction_id.slice(
                                    -5
                                  )}`}
                            </span>
                            <CopyToClipboard
                              text={
                                withdrawals.payment_id !== ""
                                  ? withdrawals.payment_id
                                  : withdrawals.stylopay_withdrawal_transaction_id
                              }
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  withDrawals.withdraw_type == "crypto"
                                    ? t(
                                        "withdraw_table.transaction_hash_copied_to_clipboard"
                                      )
                                    : t(
                                        "withdraw_table.transaction_id_copied_to_clipboard"
                                      )
                                )
                              }
                            >
                              <Button className="action-btn copy-btn item_center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#298BFF"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                  <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </h5>
                          <p className="table_amount">
                            {withdrawals.created_at}
                          </p>
                        </>
                      ) : (
                        t("n_a")
                      )}
                    </td>
                    <td>
                      <div className="table_value">
                        {withdrawals.user_amount_formatted}
                      </div>
                    </td>
                    <td>
                      {withdrawals?.wallet_address ||
                      withdrawals?.account_number ? (
                        <h5 className="table_code">
                          <span>
                            {withdrawals.withdraw_type == "crypto" &&
                            withdrawals?.wallet_address
                              ? withdrawals?.wallet_address.slice(0, 5) +
                                "......" +
                                withdrawals?.wallet_address.slice(-5)
                              : withdrawals?.account_number &&
                                withdrawals?.account_number.slice(0, 5) +
                                  "......" +
                                  withdrawals?.account_number.slice(-5)}
                          </span>
                          <CopyToClipboard
                            text={
                              withdrawals.withdraw_type == "crypto"
                                ? withdrawals?.wallet_address
                                : withdrawals?.account_number
                            }
                            onCopy={() =>
                              getSuccessNotificationMessage(
                                withdrawals.withdraw_type == "crypto"
                                  ? "Wallet address copied"
                                  : "Account number copied"
                              )
                            }
                          >
                            <Button className="action-btn copy-btn item_center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#298BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </h5>
                      ) : (
                        t("n_a")
                      )}
                    </td>
                    <td>
                    <div
                        className={`table_badge ${
                         (withdrawals.status == 3 || withdrawals.status == 5)
                            ? "fail"
                            : (withdrawals.status == 4 || withdrawals.status == 2)?"pending": "success"
                        }`}
                      >
                        {withdrawals.status_formatted}
                      </div>
                    </td>
                    <td>
                      <div className="item_center_column">
                        {[0].includes(withdrawals.status) && (
                          <Button
                            className="action-btn link-btn"
                            onClick={() => handleRefresh(withdrawals)}
                          >
                            {withdrawalStatus.buttonDisable &&
                            withdrawals?.id == withdrawalId
                              ? "Loading"
                              : "Refresh"}
                          </Button>
                        )}

                        <Button
                          className="action-btn link-btn"
                          onClick={() => {
                            setModalShow(true);
                            setTransactionId(withdrawals.id);
                          }}
                        >
                          <span>View</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {withDrawals?.data?.total_user_withdrawals > 12 ? (
            <div className="new-billings-pagination-sec">
              <Pagination
                page={page}
                pageCount={Math.ceil(
                  withDrawals?.data?.total_user_withdrawals / 12
                )}
                handlePageClick={handlePageClick}
              />
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <NoTransactionData />
      )}

      {/* {modalShow && (
        <CancelWithdrawalModal
          withdrawalId={withdrawalId}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )} */}
      {modalShow ? (
        <WithdrawTransactionViewModal
          show={modalShow}
          transactionId={transactionId}
          onHide={() => setModalShow(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  wallet: state.wallet.singleWallet,
  transaction: state.wallet.allTransaction,
  userWithdrawalsExport: state.buy.userWithdrawalsExport,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(WithDrawTransaction);
