import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const PrivacyAgreement = () => {
    return (
        <>
            <Helmet>
                <script
                    src='//in.fw-cdn.com/32218506/1186004.js'
                    chat='true'>
                </script>
            </Helmet>
            <div className="static-pag-sec">
                <div className="static-page-header">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col md="9">
                                <div className="static-section-title">
                                    <h1>Privacy Agreement</h1>
                                    <h4>Effective Date: October 8, 2024</h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <div className="static-box">
                        <Row>
                            <Col md={12}>
                                <div className="static-card">
                                    <h4>Privacy Agreement for HeraldEX</h4>

                                    <h4>Effective Date: October 8, 2024</h4>

                                    <h4>1. Introduction</h4>
                                    <p>HeraldEX ("we," "us," or "our") is committed to protecting your privacy. This Privacy Agreement outlines how we collect, use, store, and disclose your personal information when you access our website or use our services.</p>

                                    <h4>2. Information We Collect</h4>
                                    <p>We may collect the following types of personal information:</p>
                                    <ul>
                                        <li><strong>Identity Information:</strong> Name, email address, phone number, and other identifying details.</li>
                                        <li><strong>Financial Information:</strong> Payment details, bank account information, and transaction history.</li>
                                        <li><strong>Technical Information:</strong> IP address, browser type, operating system, and device information.</li>
                                        <li><strong>Usage Information:</strong> Information about how you use our website and services, including your interactions and preferences.</li>
                                    </ul>

                                    <h4>3. How We Use Your Information</h4>
                                    <p>We may use your personal information for the following purposes:</p>
                                    <ul>
                                        <li>To provide, maintain, and improve our services.</li>
                                        <li>To process transactions and send you confirmation.</li>
                                        <li>To communicate with you, including sending updates, security alerts, and support messages.</li>
                                        <li>To comply with legal obligations and protect our rights.</li>
                                        <li>To analyze usage and improve our website and services.</li>
                                    </ul>

                                    <h4>4. Disclosure of Your Information</h4>
                                    <p>We may share your personal information with:</p>
                                    <ul>
                                        <li><strong>Service Providers:</strong> Third-party vendors who assist us in providing our services (e.g., payment processors, analytics providers).</li>
                                        <li><strong>Legal Authorities:</strong> When required by law or in response to legal requests.</li>
                                        <li><strong>Business Transfers:</strong> In connection with any merger, sale, or acquisition of all or a portion of our assets.</li>
                                    </ul>

                                    <h4>5. Data Security</h4>
                                    <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no transmission over the internet is completely secure, and we cannot guarantee the security of your information.</p>

                                    <h4>6. Your Rights</h4>
                                    <p>You have certain rights regarding your personal information, including:</p>
                                    <ul>
                                        <li>The right to access and request a copy of your personal information.</li>
                                        <li>The right to correct inaccurate or incomplete information.</li>
                                        <li>The right to request deletion of your personal information.</li>
                                        <li>The right to withdraw consent for processing your data.</li>
                                    </ul>

                                    <h4>7. Changes to This Privacy Agreement</h4>
                                    <p>We may update this Privacy Agreement from time to time. We will notify you of any changes by posting the new agreement on our website with a revised effective date.</p>

                                    <h4>8. Contact Us</h4>
                                    <p>If you have any questions or concerns about this Privacy Agreement or our privacy practices, please contact us at: <a href="mailto:support@herald.exchange">support@herald.exchange</a>.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PrivacyAgreement