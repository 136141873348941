import React, { useState, useEffect } from "react";
import { Button, Image, InputGroup, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { twoStepVerificationStart } from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { ButtonLoader } from "../helper/Loader";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import CopyToClipboard from "react-copy-to-clipboard";
import NoDataFound from "../helper/NoDataFound";
import "../Auth/Two_set.css";

const Authenticator = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("authenticator");
  const twoStepVerification = useSelector(
    (state) => state.user.twoStepVerification
  );
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [step, setStep] = useState(1);
  const [authstep, setAuthStep] = useState(1);

  const tfaSchema = Yup.object().shape({
    otp: Yup.string().required(t("required")),
    // password: Yup.string()
    //   .required(t("required"))
    //   .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleSubmit = (values) => {
    if (otp) {
      if (otp.length === 6) {
        dispatch(
          twoStepVerificationStart({
            verification_code: otp,
            email: props.authenticatorModal.email,
            password: props.authenticatorModal.password,
            ...values,
          })
        );
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !twoStepVerification.loading &&
      Object.keys(twoStepVerification.data).length > 0
    ) {
      // props.closeAuthenticatorModal();
      setStep(2);
    }
    setSkipRender(false);
  }, [twoStepVerification]);

  return (
    <>
      <Modal
        size="md"
        centered
        show={props.authenticatorModal}
        onHide={props.closeAuthenticatorModal}
        className="auth_2fa_modal"
        backdrop="static"
      >
        {step == 1 ? <Modal.Header closeButton></Modal.Header> : null}
        <Modal.Body>
          <div className="auth_2fa_content">
            <div className="auth_2fa_avater out_space_mx_1_5">
              <Image
                className="auth_2fa-sec"
                src={window.location.origin + "/img/phase_1/auth/auth_scan.svg"}
                alt="onboard"
              />
            </div>
            {step == 1 ? (
              <>
                <div className="auth_2fa_titles out_space_mx_1_5">
                  <h2>
                    {props?.authenticatorModal.type == "email"
                      ? t("otp_verification")
                      : authstep == 1 && t("code_from_virtual_device")}
                  </h2>
                  {props?.authenticatorModal.type !== "email" &&
                    authstep == 1 && (
                      <p>{t("code_from_your_virtual_device")}</p>
                    )}
                  {authstep == 2 && (
                    <>
                      <h2>{t("verify_authentication_code")}</h2>
                      <p>{t("enter_the_6_digit_code_in_authenticator")} </p>
                    </>
                  )}
                </div>
                {Object.keys(props?.authentication.data).length > 0 ? (
                  <>
                    {authstep == 1 ? (
                      <>
                        <div className="auth_2fa_qr_scan out_space_mx_1_5">
                          <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(
                              props.authentication?.data?.qr_code
                            )}`}
                            alt="QR Code"
                            className="auth_2fa_qr"
                          />
                        </div>
                        <div className="auth_2fa_action out_space_mx_1_5">
                          <Button
                            className="action-btn primary w-100"
                            onClick={() => {
                              setAuthStep(2);
                            }}
                          >
                            {t("continue")}
                          </Button>
                        </div>
                        <div className="auth_2fa_action out_space_mx_1_5">
                          <Button
                            className="action-btn primary w-100"
                            onClick={() => {
                              props.closeAuthenticatorModal();
                              navigate("/")
                            }}
                          >
                            {t("skip")}
                          </Button>
                        </div>
                        <div className="auth_2fa_space item_center out_space_mx_1_5">
                          <p>{t("or_enter_the_code_manually")}</p>
                        </div>
                        <div className="auth_2fa_code">
                          <div className="auth_2fa_code_card">
                            <span>
                              {props?.authentication?.data?.google2fa_secret}
                            </span>{" "}
                          </div>

                          <CopyToClipboard
                            text={props.authentication.data.google2fa_secret}
                          >
                            <Button
                              className=" action-btn overlay item_center_column p-0"
                              onClick={() =>
                                getSuccessNotificationMessage(t("copied"))
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#298BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="auth_2fa_otp out_space_mx_1_5">
                          <Formik
                            initialValues={{
                              otp: "",
                              // password: "",
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={tfaSchema}
                          >
                            {({ values, setFieldValue }) => (
                              <FORM>
                                <div className="auth_2fa_action">
                                  <OtpInput
                                    className="otp-box"
                                    value={otp}
                                    onChange={setOtp}
                                    inputType="tel"
                                    numInputs={6}
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )}
                                    shouldAutoFocus
                                  />
                                  <div className="auth_2fa_action item_flex_x_5">
                                    <Button
                                      type="button"
                                      className="action-btn secondary w-100"
                                      onClick={() => {
                                        setAuthStep(1);
                                      }}
                                    >
                                      {t("back")}
                                    </Button>
                                    <Button
                                      type="submit"
                                      className="action-btn primary w-100"
                                      onClick={() => handleSubmit(values)}
                                      disabled={
                                        twoStepVerification.buttonDisable
                                      }
                                    >
                                      {twoStepVerification.buttonDisable ? (
                                        <ButtonLoader />
                                      ) : (
                                        // t("activate")
                                        t("complete")
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </FORM>
                            )}
                          </Formik>
                        </div>
                        <div className="auth_modal_resend_otp item_center_x_5">
                          <p>{t("having_trouble")}?</p>
                          <Button
                            type="button"
                            className="resend_otp_btn"
                            onClick={() => {
                              navigate("/page/help");
                            }}
                          >
                            {t("help_center")}
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <React.Fragment>
                <div className="backup-code-modal-sec">
                  <h3>{t("backup_codes")}</h3>
                  {twoStepVerification.data.backup_codes.length > 0 ? (
                    <React.Fragment>
                      <p>
                        <strong>{t("lose_these_codes")}.</strong>{" "}
                        {t("copy_them")}
                      </p>
                      <div className="backup-code-box">
                        {twoStepVerification.data.backup_codes.map(
                          (item, key) => (
                            <div key={key} className="backup-code-card">
                              {item}
                            </div>
                          )
                        )}
                      </div>
                      <div className="backup-code-btn-sec">
                        <CopyToClipboard
                          text={twoStepVerification.data.backup_codes.join(
                            ", "
                          )}
                        >
                          <Button
                            className="action-btn overlay w-100 item_flex_x_5 justify-content-center"
                            onClick={() =>
                              getSuccessNotificationMessage(t("copied"))
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                            {t("copy")}
                          </Button>
                        </CopyToClipboard>
                        <Button
                          onClick={() => navigate("/onboarding")}
                          className="action-btn primary  w-100"
                        >
                          {t("continue")}
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Authenticator;
