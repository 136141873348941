import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { nationalitiesListStart, registerStart } from "../../store/actions/UserAction";
import configuration from "react-global-configuration";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import "react-date-picker/dist/DatePicker.css";
import OtpVerification from "./OtpVerification";
import Authenticator from "./Authenticator";
import { ButtonLoader } from "../helper/Loader";
import { mobileCodesListStart } from "../../store/actions/RecipientAction";

const RegisterIndex = (props) => {
  const t = useTranslation("register");
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [eventKey, setEventKey] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [selectedTitle, setSelectedTile] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [authenticatorModal, setAuthenticatorModal] = useState(false);
  const [filteredNationalities, setNationalities] = useState([])
  const [filteredMobileCode, setMobileCode] = useState([])

  const emailVerify = useSelector((state) => state.user.emailVerify);
  const register = useSelector((state) => state.user.register);
  const mobileCodesList = useSelector((state) => state.recipient.mobileCodesList);
  const nationalitiesList = useSelector((state) => state.user.nationalitiesList);


  const titleOptions = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    {
      label: "Ms",
      value: "Miss",
    },
  ];

  useEffect(() => {
    setStep(1);
  }, []);

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      setShowOTPVerifyModal({
        ...register.data,
        type: "email",
      });
    }
    setSkipRender(false);
  }, [register]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("title.required")),
    user_type: Yup.string().required(t("required")),
    first_name: Yup.string()
      .min(3, t("first_name.min_length_invalid"))
      .max(25, t("first_name.max_length_invalid"))
      .matches(/^[A-Za-z]+$/, t("first_name.invalid"))
      .required(t("first_name.required")),
    middle_name: Yup.string()
      .min(3, t("middle_name.min_length_invalid"))
      .max(25, t("middle_name.max_length_invalid"))
      .matches(/^[A-Za-z]+$/, t("middle_name.invalid")),

    last_name: Yup.string()
      // .min(3, t("last_name.min_length_invalid"))
      .max(25, t("last_name.max_length_invalid"))
      .matches(/^[A-Za-z]+$/, t("last_name.invalid"))
      .required(t("last_name.required")),
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required"))
      .max(128, t("email.max_length_invalid")),
    nationality: Yup.string().required(t("first_name.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^\S+$/, t("password.space_invalid"))
      // .matches(/[A-Z]/, t("password.invalid"))
      // .matches(/[a-z]/, "One smaller case required")
      // .matches(/[0-9]/, t("password.number_invalid"))
      // .matches(/[\W_]/, t("password.special_character_invalid"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided

          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(8, t("password.min_length_invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.passwords_do_not_match")
        ),
      }),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile_number.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("mobile_number.required"))
      .matches(/^(?=.*[0-9])/, t("mobile_number.space_values_not_valid")),
    // website_url: Yup.string()
    //   .required(t("website_url.required"))
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     t("website_url.invalid_url")
    //   ),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const HandleRegister = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      setEventKey("register");
      props.dispatch(
        registerStart({
          ...values,
          mobile: mobile,
          country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      (localStorage.getItem("userId") && localStorage.getItem("token")) ||
      (sessionStorage.getItem("userId") && sessionStorage.getItem("token"))
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      eventKey == "register" &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      if (emailVerify.data.google2fa_secret) {
        setShowOTPVerifyModal(false);
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
      }
    }
    setSkipRender(false);
  }, [emailVerify]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isSelected ? "#f7f7f7" : "#fff",
    //     color: "#000",
    //   };
    // },
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
    //   color: state.isFocused || state.isSelected ? "#000" : "#000",
    //   ":hover": {
    //     backgroundColor: "#F9C201",
    //     color: "#000",
    //   },
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#F9C201" 
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected || state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (!skipRender && !mobileCodesList.buttonDisable && Object.keys(mobileCodesList.data).length > 0) {
      if (mobileCodesList.data.mobile_country_codes.length > 0) {
        setMobileCode(mobileCodesList.data.mobile_country_codes)
      }
    }
    setSkipRender(false);
  }, [mobileCodesList])

  useEffect(() => {
    if (!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0) {
      if (nationalitiesList.data.total_nationalities > 0) {
        setNationalities(nationalitiesList.data.nationalities.map((data) => ({ label: data.country_name, value: data.alpha_2_code })))
        setSelectedNationality(nationalitiesList.data.nationalities?.find((option) => option.value == "AE"))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList])

  useEffect(() => {
    props.dispatch(mobileCodesListStart())
    props.dispatch(nationalitiesListStart({ all: 1 }))
  }, []);

  return (
    <>
      <div className="auth-login-avater">
        <div className="auth-login-avater-card over_space_2">
          <div className="auth-login-avater-logo">
            <Link to="/">
              <Image
                className="logo"
                src={configuration.get("configData.site_logo")}
                type="image/png"
              />
            </Link>
          </div>
          <div className="auth-login-avater-info">
            <h2>Effortlessly Exchange Currency</h2>
            <p>
              Utilize our platform to exchange currency seamlessly, allowing you
              to effortlessly convert your funds with competitive rates and
              minimal delays, ensuring a smooth and efficient transaction
              process for all your international financial needs.
            </p>
          </div>
        </div>
        <div className="auth-login-avater-frame over_space_2">
          <Image
            className="avater-frame-auth"
            src={window.location.origin + "/img/phase_1/auth/auth-avater.png"}
            type="image/png"
          />
        </div>
      </div>
      <div className="auth-login-form-frame">
        <Formik
          initialValues={{
            user_type: "",
            title: "",
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            mobile: "",
            password: "",
            password_confirmation: "",
            // website_url: "",
            country_code: "",
            nationality: filteredNationalities?.find((option) => option.value == "AE")?.value,
            // mobile: "",
          }}
          validationSchema={validationSchema}
          onSubmit={HandleRegister}
          innerRef={formRef}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <FORM>
              <div className="form_card register-form-card over_space_4">
                <div className="form_card_titles out_space_mx_1_5">
                  <h2>{t("heading")}</h2>
                </div>
                <div className="form_card_form out_space_mx_1_5">
                  <Row>
                    {/* <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("account_type")} <span>*</span>
                        </Form.Label>
                        <div className="gender-check-box">
                          <div className="pretty p-default p-round">
                            <Field
                              name="user_type"
                              type="radio"
                              // disabled={profile.data.user_type}
                              checked={values.user_type == 1}
                              onChange={() => setFieldValue("user_type", "1")}
                            />
                            <div className="state p-primary-o">
                              <label>{t("individual")}</label>
                            </div>
                          </div>
                          <div className="pretty p-default p-round">
                            <Field
                              name="user_type"
                              type="radio"
                              // disabled={profile.data.user_type}
                              checked={values.user_type == 2}
                              onChange={() => setFieldValue("user_type", "2")}
                            />
                            <div className="state p-primary-o">
                              <label>{t("business")}</label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="user_type"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col> */}
                    <Col sm={12} lg={12} xl={4}>
                      <Form.Group
                        delivery_country
                        controlId="formBasicEmail"
                        className="mb-3"
                      >
                        <Form.Label>
                          {t("title")} <span>*</span>
                        </Form.Label>
                        <Select
                          value={selectedTitle}
                          options={titleOptions}
                          styles={customStyles}
                          isSearchable={false}
                          onChange={(data) => {
                            setSelectedTile(data);
                            setFieldValue("title", data.value);
                          }}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="title"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={8}>
                      <Form.Group
                        delivery_country
                        controlId="formBasicEmail"
                        className="mb-3"
                      >
                        <Form.Label>
                          {t("first_name.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="first_name"
                          placeholder={t("first_name.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} xl={6}>
                      <Form.Group
                        delivery_country
                        controlId="formBasicEmail"
                        className="mb-3"
                      >
                        <Form.Label>
                          {t("middle_name.label")} <span></span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="middle_name"
                          // onKeyDown={(e) => {
                          //   if (
                          //     !/[A-Z]|[a-z]|[ \t]|Backspace|Spacebar|Tab/.test(
                          //       e.key
                          //     ) &&
                          //     !(
                          //       e.ctrlKey &&
                          //       (e.key === "v" ||
                          //         e.key === "c" ||
                          //         e.key === "x" ||
                          //         e.key === "a")
                          //     )
                          //   ) {
                          //     e.preventDefault();
                          //   }
                          // }}
                          placeholder={t("middle_name.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="middle_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          {t("last_name.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          // onKeyDown={(e) => {
                          //   if (
                          //     !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                          //       e.key
                          //     ) &&
                          //     !(
                          //       e.ctrlKey &&
                          //       (e.key === "v" ||
                          //         e.key === "c" ||
                          //         e.key === "x" ||
                          //         e.key === "a")
                          //     )
                          //   ) {
                          //     e.preventDefault();
                          //   }
                          // }}
                          className="form-control"
                          name="last_name"
                          placeholder={t("last_name.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          {t("email.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder={t("email.placeholder")}
                          maxLength={128}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col sm={12} lg={12} xl={6}>
                        <Form.Group
                          controlId="formBasicEmail"
                          className="mb-3"
                        >
                          <Form.Label>
                            {t("website_url.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="website_url"
                            placeholder={t("website_url.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="website_url"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col> */}
                    <Col sm={12} lg={6} xl={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {t("mobile_number.label")} <span>*</span>
                        </Form.Label>
                        <PhoneInput
                          countries={filteredMobileCode}
                          defaultCountry="AE"
                          placeholder={t("mobile_number.placeholder")}
                          value={values.mobile}
                          onChange={(value) => setFieldValue("mobile", value)}
                          international
                          countryCallingCodeEditable={false}
                          className={`${touched.mobile && errors.mobile ? "is-invalid" : ""
                            }`}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={12}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          {t("nationality")} <span>*</span>
                        </Form.Label>
                        <Select
                          value={selectedNationality}
                          options={filteredNationalities}
                          styles={customStyles}
                          onChange={(data) => {
                            setSelectedNationality(data);
                            setFieldValue("nationality", data.value);
                          }}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="nationality"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          {t("password.label")} <span>*</span>
                        </Form.Label>
                        <InputGroup className="mb-0">
                          <Field
                            type={loginPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder={t("password.placeholder")}
                            className="form-control"
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={() =>
                              setLoginPasswordVisible(!loginPasswordVisible)
                            }
                          >
                            {loginPasswordVisible ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#DDE1E6"
                                  d="M12 16.33c-2.39 0-4.33-1.94-4.33-4.33S9.61 7.67 12 7.67s4.33 1.94 4.33 4.33-1.94 4.33-4.33 4.33zm0-7.16c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83 2.83-1.27 2.83-2.83S13.56 9.17 12 9.17z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M12 21.02c-3.76 0-7.31-2.2-9.75-6.02-1.06-1.65-1.06-4.34 0-6 2.45-3.82 6-6.02 9.75-6.02s7.3 2.2 9.74 6.02c1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54c-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38 2.16 3.39 5.25 5.33 8.48 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-2.16-3.39-5.25-5.33-8.48-5.33z"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#DDE1E6"
                                  d="M9.47 15.28c-.19 0-.38-.07-.53-.22A4.305 4.305 0 017.67 12c0-2.39 1.94-4.33 4.33-4.33 1.15 0 2.24.45 3.06 1.27a.75.75 0 010 1.06L10 15.06c-.15.15-.34.22-.53.22zM12 9.17a2.834 2.834 0 00-2.46 4.23l3.86-3.86c-.42-.24-.9-.37-1.4-.37z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M5.6 18.51c-.17 0-.35-.06-.49-.18-1.07-.91-2.03-2.03-2.85-3.33-1.06-1.65-1.06-4.34 0-6C4.7 5.18 8.25 2.98 12 2.98c2.2 0 4.37.76 6.27 2.19a.75.75 0 01-.9 1.2c-1.64-1.24-3.5-1.89-5.37-1.89-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38s1.61 2.18 2.56 3c.31.27.35.74.08 1.06-.14.17-.35.26-.56.26zM12 21.02c-1.33 0-2.63-.27-3.88-.8a.75.75 0 01-.4-.98c.16-.38.6-.56.98-.4 1.06.45 2.17.68 3.29.68 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-.31-.49-.65-.96-1.01-1.4a.76.76 0 01.11-1.06.75.75 0 011.06.11c.39.48.77 1 1.11 1.54 1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M12.69 16.27c-.35 0-.67-.25-.74-.61-.08-.41.19-.8.6-.87 1.1-.2 2.02-1.12 2.22-2.22.08-.41.47-.67.88-.6.41.08.68.47.6.88-.32 1.73-1.7 3.1-3.42 3.42-.05-.01-.09 0-.14 0zM2 22.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L8.94 14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.47 7.47c-.15.15-.34.22-.53.22zM14.53 10.22c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.47-7.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L15.06 10c-.15.15-.34.22-.53.22z"
                                ></path>
                              </svg>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} xl={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          {t("password_confirmation.label")} <span>*</span>
                        </Form.Label>
                        <InputGroup className="mb-0">
                          <Field
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="password_confirmation"
                            placeholder={t("password_confirmation.placeholder")}
                            className="form-control"
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          >
                            {confirmPasswordVisible ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#DDE1E6"
                                  d="M12 16.33c-2.39 0-4.33-1.94-4.33-4.33S9.61 7.67 12 7.67s4.33 1.94 4.33 4.33-1.94 4.33-4.33 4.33zm0-7.16c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83 2.83-1.27 2.83-2.83S13.56 9.17 12 9.17z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M12 21.02c-3.76 0-7.31-2.2-9.75-6.02-1.06-1.65-1.06-4.34 0-6 2.45-3.82 6-6.02 9.75-6.02s7.3 2.2 9.74 6.02c1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54c-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38 2.16 3.39 5.25 5.33 8.48 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-2.16-3.39-5.25-5.33-8.48-5.33z"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#DDE1E6"
                                  d="M9.47 15.28c-.19 0-.38-.07-.53-.22A4.305 4.305 0 017.67 12c0-2.39 1.94-4.33 4.33-4.33 1.15 0 2.24.45 3.06 1.27a.75.75 0 010 1.06L10 15.06c-.15.15-.34.22-.53.22zM12 9.17a2.834 2.834 0 00-2.46 4.23l3.86-3.86c-.42-.24-.9-.37-1.4-.37z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M5.6 18.51c-.17 0-.35-.06-.49-.18-1.07-.91-2.03-2.03-2.85-3.33-1.06-1.65-1.06-4.34 0-6C4.7 5.18 8.25 2.98 12 2.98c2.2 0 4.37.76 6.27 2.19a.75.75 0 01-.9 1.2c-1.64-1.24-3.5-1.89-5.37-1.89-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38s1.61 2.18 2.56 3c.31.27.35.74.08 1.06-.14.17-.35.26-.56.26zM12 21.02c-1.33 0-2.63-.27-3.88-.8a.75.75 0 01-.4-.98c.16-.38.6-.56.98-.4 1.06.45 2.17.68 3.29.68 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-.31-.49-.65-.96-1.01-1.4a.76.76 0 01.11-1.06.75.75 0 011.06.11c.39.48.77 1 1.11 1.54 1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02z"
                                ></path>
                                <path
                                  fill="#DDE1E6"
                                  d="M12.69 16.27c-.35 0-.67-.25-.74-.61-.08-.41.19-.8.6-.87 1.1-.2 2.02-1.12 2.22-2.22.08-.41.47-.67.88-.6.41.08.68.47.6.88-.32 1.73-1.7 3.1-3.42 3.42-.05-.01-.09 0-.14 0zM2 22.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L8.94 14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.47 7.47c-.15.15-.34.22-.53.22zM14.53 10.22c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.47-7.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L15.06 10c-.15.15-.34.22-.53.22z"
                                ></path>
                              </svg>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="password_confirmation"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-0">
                        <Form.Label className="default-form-label w-100">
                          {t("account_type")} <span>*</span>
                        </Form.Label>
                        <div className="gender-check-box">
                          <div className="pretty p-default p-round">
                            <Field
                              name="user_type"
                              type="radio"
                              // disabled={profile.data.user_type}
                              checked={values.user_type == 1}
                              onChange={() => setFieldValue("user_type", "1")}
                            />
                            <div className="state p-primary-o">
                              <label>{t("individual")}</label>
                            </div>
                          </div>
                          <div className="pretty p-default p-round">
                            <Field
                              name="user_type"
                              type="radio"
                              // disabled={profile.data.user_type}
                              checked={values.user_type == 2}
                              onChange={() => setFieldValue("user_type", "2")}
                            />
                            <div className="state p-primary-o">
                              <label>{t("business")}</label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="user_type"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className=" form_card_action out_space_mx_1_5">
                  <Button
                    className="action-btn primary w-100"
                    type="submit"
                    disabled={register.buttonDisable}
                  >
                    {register.buttonDisable ? (
                      <ButtonLoader />
                    ) : (
                      t("login_btn.text")
                    )}
                  </Button>
                </div>
                <div className="form_card_others">
                  <p className="out_space_mx_1_5">
                    By continuing you accept our{" "}
                    <Link to="/terms-conditions" className="auth_link">
                      Terms & conditions
                    </Link>{" "}
                    and{" "}
                    <Link to="/privacy-agreement" className="auth_link">
                      Privacy agreement
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <span>{t("already_have_an_account")}? </span>
                    <Link to="/login" className="auth_link">
                      {t("login")}
                    </Link>
                  </p>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>

      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={closeOtpVerifyModal}
          type="email"
          password={formRef.current?.values.password}
        />
      ) : null}
      {authenticatorModal ? (
        <Authenticator
          authenticatorModal={authenticatorModal}
          authentication={emailVerify}
          login={0}
          closeAuthenticatorModal={() => setAuthenticatorModal(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.user.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterIndex);