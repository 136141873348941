import React, { useState, useEffect } from "react";
import { Button, Image, InputGroup, Modal, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import {
  get2faDetailsStart,
  twoStepVerificationStart,
} from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { ButtonLoader } from "../helper/Loader";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import CopyToClipboard from "react-copy-to-clipboard";
import NoDataFound from "../helper/NoDataFound";
import "../Auth/Two_set.css";
import Skeleton from "react-loading-skeleton";

const Authenticator = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("authenticator");

  const twoStepVerification = useSelector((state) => state.user.twoStepVerification);
  const get2faDetails = useSelector((state) => state.user.get2faDetails);
  const profile = useSelector((state) => state.user.profile);

  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [step, setStep] = useState(1);
  const [authstep, setAuthStep] = useState(1);

  const tfaSchema = Yup.object().shape({
    otp: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleSubmit = (values) => {
    if (otp) {
      if (otp.length === 6) {
        dispatch(
          twoStepVerificationStart({
            type: "setup",
            verification_code: otp,
            email: profile.data.email,
            ...values,
          })
        );
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !twoStepVerification.loading &&
      Object.keys(twoStepVerification.data).length > 0
    ) {
      // props.closeAuthenticatorModal();
      setStep(2);
    }
    setSkipRender(false);
  }, [twoStepVerification]);

  useEffect(() => {
    dispatch(get2faDetailsStart());
  }, []);

  return (
    <>
      <Modal
        size="md"
        centered
        show={props.authenticatorModal}
        onHide={props.closeAuthenticatorModal}
        className="auth_2fa_modal"
        backdrop="static"
      >
        {step == 1 ? <Modal.Header closeButton></Modal.Header> : null}
        <Modal.Body>
          <div className="auth_2fa_content">
            <div className="auth_2fa_avater out_space_mx_1_5">
              <Image
                className="auth_2fa-sec"
                src={window.location.origin + "/img/phase_1/auth/auth_scan.svg"}
                alt="onboard"
              />
            </div>
            {step == 1 ? (
              <>
                <div className="auth_2fa_titles out_space_mx_1_5">
                  <h2>2FA Setup</h2>
                  {/* <h2>
                    {props?.authenticatorModal.type == "email"
                      ? t("otp_verification")
                      : authstep == 1 && t("code_from_virtual_device")}
                  </h2>
                  {props?.authenticatorModal.type !== "email" &&
                    authstep == 1 && (
                      <p>{t("code_from_your_virtual_device")}</p>
                    )}
                      */}
                  {authstep == 2 && (
                    <>
                      {/* <h2>{t("verify_authentication_code")}</h2> */}
                      <p>{t("enter_the_6_digit_code_in_authenticator")} </p>
                    </>
                  )}
                </div>
                {get2faDetails.buttonDisable ? (
                  <>
                    <Skeleton width={"100%"} height={200} />
                  </>
                ) : Object.keys(get2faDetails.data).length > 0 ? (
                  <>
                    {authstep == 1 ? (
                      <>
                        <div className="auth_2fa_qr_scan out_space_mx_1_5">
                          <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(
                              get2faDetails.data?.qr_code
                            )}`}
                            alt="QR Code"
                            className="auth_2fa_qr"
                          />
                        </div>
                        <div className="auth_2fa_action out_space_mx_1_5">
                          <Button
                            className="action-btn primary w-100"
                            onClick={() => {
                              setAuthStep(2);
                            }}
                          >
                            {t("continue")}
                          </Button>
                        </div>
                        <div className="auth_2fa_space item_center out_space_mx_1_5">
                          <p>{t("or_enter_the_code_manually")}</p>
                        </div>
                        <div className="auth_2fa_code">
                          <div className="auth_2fa_code_card">
                            <span>{get2faDetails?.data?.google2fa_secret}</span>{" "}
                          </div>

                          <CopyToClipboard
                            text={get2faDetails.data.google2fa_secret}
                          >
                            <Button
                              className=" action-btn overlay item_center_column p-0"
                              onClick={() =>
                                getSuccessNotificationMessage(t("copied"))
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#298BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="auth_2fa_otp out_space_mx_1_5">
                          <Formik
                            initialValues={{
                              otp: "",
                              password: "",
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={tfaSchema}
                          >
                            {({ values, setFieldValue, isValid }) => (
                              <FORM className="auth-form-sec">
                                <div className="auth_2fa_action">
                                  <OtpInput
                                    className="otp-box"
                                    value={otp}
                                    onChange={setOtp}
                                    inputType="tel"
                                    numInputs={6}
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )}
                                    shouldAutoFocus
                                  />
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("password.name")}
                                      <span>*</span>
                                    </Form.Label>
                                    <InputGroup className="mb-0 mt-0">
                                      <Field
                                        type={
                                          loginPasswordVisible
                                            ? "text"
                                            : "password"
                                        }
                                        name="password"
                                        placeholder="password"
                                        className="form-control"
                                      />
                                      <InputGroup.Text
                                        id="basic-addon2"
                                        onClick={() =>
                                          setLoginPasswordVisible(
                                            !loginPasswordVisible
                                          )
                                        }
                                      >
                                        {loginPasswordVisible ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <g clipPath="url(#clip0_1_56)">
                                              <path
                                                fill="#3C3C41"
                                                d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1_56">
                                                <path
                                                  fill="#fff"
                                                  d="M0 0H24V24H0z"
                                                ></path>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="19"
                                            height="16"
                                            viewBox="0 0 19 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                              fill="black"
                                            />
                                          </svg>
                                        )}
                                      </InputGroup.Text>
                                    </InputGroup>
                                    <ErrorMessage
                                      component={"div"}
                                      name="password"
                                      className="text-danger text-right"
                                    />
                                  </Form.Group>
                                  <div className="auth_2fa_action item_flex_x_5">
                                    <Button
                                      type="button"
                                      className="action-btn secondary w-100"
                                      onClick={() => {
                                        setAuthStep(1);
                                      }}
                                    >
                                      {t("back")}
                                    </Button>
                                    <Button
                                      type="submit"
                                      className="action-btn primary w-100"
                                      onClick={() => handleSubmit(values)}
                                      disabled={
                                        twoStepVerification.buttonDisable
                                      }
                                    >
                                      {twoStepVerification.buttonDisable ? (
                                        <ButtonLoader />
                                      ) : (
                                        // t("activate")
                                        t("complete")
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </FORM>
                            )}
                          </Formik>
                        </div>
                        <div className="auth_modal_resend_otp item_center_x_5">
                          <p>{t("having_trouble")}?</p>
                          <Button
                            type="button"
                            className="resend_otp_btn"
                            onClick={() => {
                              navigate("/page/help");
                            }}
                          >
                            {t("help_center")}
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <React.Fragment>
                <div className="backup-code-modal-sec">
                  <h3>{t("backup_codes")}</h3>
                  {twoStepVerification.data.backup_codes.length > 0 ? (
                    <React.Fragment>
                      <p>
                        <strong>{t("lose_these_codes")}.</strong>{" "}
                        {t("copy_them")}
                      </p>
                      <div className="backup-code-box">
                        {twoStepVerification.data.backup_codes.map(
                          (item, key) => (
                            <div key={key} className="backup-code-card">
                              {item}
                            </div>
                          )
                        )}
                      </div>
                      <div className="backup-code-btn-sec">
                        <CopyToClipboard
                          text={twoStepVerification.data.backup_codes.join(
                            ", "
                          )}
                        >
                          <Button
                            className="action-btn overlay w-100 item_flex_x_5 justify-content-center"
                            onClick={() =>
                              getSuccessNotificationMessage(t("copied"))
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                            {t("copy")}
                          </Button>
                        </CopyToClipboard>
                        <Button
                          onClick={() => props.closeAuthenticatorModal()}
                          className="action-btn primary  w-100"
                        >
                          {t("continue")}
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Authenticator;
