import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Help = () => {
    return (
        <>
        <Helmet>
                <script
                    src='//in.fw-cdn.com/32218506/1186004.js'
                    chat='true'>
                </script>
            </Helmet>
        <div className="static-pag-sec">
            <div className="static-page-header">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <div className="static-section-title">
                                <h1>Help Center</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid>
                <div className="static-box">
                    <Row>
                        <Col md={12}>
                            <div className="static-card">
                                <p>Welcome to the HeraldEX Help Center! We’re here to assist you with any questions or issues you may encounter while using our platform. Below, you'll find helpful resources and guides to make your experience smooth and hassle-free.</p>

                                <h4>How to Get Support</h4>
                                <p>If you can’t find the answer you’re looking for in the FAQs, you can reach out to us through the following support channels:</p>
                                <ul>
                                    <li>
                                        <strong>Email Support</strong><br />
                                        📧 Email: <a href="mailto:support@herald.exchange">support@herald.exchange</a><br />
                                        Send us your queries, and we’ll respond within 24–48 hours.
                                    </li>
                                    <li>
                                        <strong>Live Chat</strong><br />
                                        Need quick assistance? Our live chat feature allows you to connect with a support agent in real time. Click the chat icon at the bottom of the screen to start.
                                    </li>
                                </ul>

                                <h4>Troubleshooting Guides</h4>
                                <p>If you encounter any technical issues, these troubleshooting guides can help you solve the problem quickly:</p>
                                <ul>
                                    <li>
                                        <strong>I’m having trouble logging in:</strong> Make sure you're using the correct email and password. If you’ve forgotten your password, click on the “Forgot Password” link on the login page to reset it.
                                    </li>
                                    <li>
                                        <strong>My transaction is pending:</strong> Cryptocurrency transactions can take time depending on network traffic. If your transaction is still pending after a long period, contact our support team for assistance.
                                    </li>
                                    <li>
                                        <strong>I can't access my account:</strong> Ensure that your account is not locked or suspended due to suspicious activity. Contact our support team if you believe this is an error.
                                    </li>
                                </ul>

                                <h4>Get in Touch</h4>
                                <p>If you still need assistance, don’t hesitate to contact us. Our team is here to ensure your experience on Herald.Exchange is seamless and secure.</p>
                                <div className="call-to-action-btn-sec">
                                    <Link className="new-default-primary-btn" to="/contact-us">
                                        Contact Us
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="#171717" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
        </>
    )
}

export default Help